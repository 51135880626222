import React, { useEffect, useMemo, useState } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetFlipperState from './interfaces/AssetFlipperState';
import AssetEditorState from '../AssetEditor/interfaces/AssetEditorState';
import ImageCropperWrapper from '../AssetGalleryCropper/components/image-cropper-wrapper';

import './styles/main.scss';

/**
 * Previews the asset flipper functionalities.
 * This component is used to preview the flipping/rotate features on an asset.
 */
const AssetFlipper: React.FC = () => {
    const { assetFlipperState } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: { assetFlipperState: 'assetFlipperState' }
    });

    const {
        scaleX = 1,
        scaleY = 1,
        rotationAngle = 0
    } = useComponentStore<AssetFlipperState>('AssetFlipper', {
        fields: { scaleX: 'scaleX', scaleY: 'scaleY', rotationAngle: 'rotationAngle' }
    });

    const getImageBoxTransformString = () => {
        return `rotate(${rotationAngle}deg) scaleX(${scaleX}) scaleY(${scaleY})`;
    };

    const [imageBoxStyle, setImageBoxStyle] = useState<React.CSSProperties>({
        transform: getImageBoxTransformString()
    });

    useEffect(() => {
        ComponentStoreHelpers.setData('AssetFlipper', {
            scaleX: assetFlipperState?.scaleX ?? 1,
            scaleY: assetFlipperState?.scaleY ?? 1,
            rotationAngle: assetFlipperState?.rotationAngle ?? 0
        });

        return () => {
            ComponentStoreHelpers.setData('AssetFlipper', { scaleX: 1, scaleY: 1, rotationAngle: 0 });
        };
    }, []);

    useMemo(() => {
        setImageBoxStyle({ transform: getImageBoxTransformString() });
    }, [scaleX, scaleY, rotationAngle]);

    return <ImageCropperWrapper locked={true} imageBoxStyle={imageBoxStyle} reactCropClassName={'asset-flipper__crop'} />;
};

export default AssetFlipper;
