import Translation from 'components/data/Translation';
import AssetFlipperControllerItem from '../interfaces/asset-flipper-controller-item';

/**
 * An array of objects representing the items in the asset flipper controller.
 */
const assetFlipperControllerItems: AssetFlipperControllerItem[] = [
    {
        icon: 'rotate_right',
        action: 'rotateRight',
        tooltip: Translation.get('assetGalleryDialog.assetFlipper.rotateRight', 'content-space')
    },
    {
        icon: 'rotate_left',
        action: 'rotateLeft',
        tooltip: Translation.get('assetGalleryDialog.assetFlipper.rotateLeft', 'content-space')
    },
    {
        icon: 'flip',
        action: 'flipHorizontal',
        tooltip: Translation.get('assetGalleryDialog.assetFlipper.FlipHorizontal', 'content-space')
    },
    {
        icon: 'flip',
        action: 'flipVertical',
        tooltip: Translation.get('assetGalleryDialog.assetFlipper.FlipVertical', 'content-space')
    }
];

export default assetFlipperControllerItems;
