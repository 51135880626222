import React, { useEffect } from 'react';
import AssetGalleryData from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryData';
import OutpaintEditorCanvas from 'components/assets/AssetOutpaintEditor/components';
import { OutpaintCanvasOutput } from 'components/assets/AssetOutpaintEditor/interfaces/outpaint';
import AssetImageCropper from './asset-image-cropper';
import '../styles/asset-cropper-view.scss';
interface Props {
    componentKey: string;
    ratio: number;
    outputWidth: number;
    outputHeight: number;
    maxOutputWidth: number;
    maxOutputHeight: number;
    imageFormat?: string;
    imageQuality: number;
    value: AssetGalleryData;
    config: any;
    cropMode: string;
    locked?: boolean;
    imageBoxStyle?: React.CSSProperties;
    reactCropClassName?: string;
    outpaintMode: string;
    onChange: (asset) => void;
    onOutpaintChange: (asset: OutpaintCanvasOutput) => void;
}
/**
 * Image cropper view
 * This displays the cropped component with a specific image in it.
 */
const AssetImageCropperView: React.FC<Props> = ({
    value: image,
    componentKey,
    config,
    onChange,
    onOutpaintChange,
    cropMode,
    ratio,
    outputWidth,
    outputHeight,
    maxOutputWidth, // number (pixels)
    maxOutputHeight, // number (pixels)
    imageFormat,
    imageQuality,
    locked = false,
    imageBoxStyle,
    reactCropClassName,
    outpaintMode
}) => {
    useEffect(() => {
        // Disable lock if the crop mode is fixed or ratio based and only one of the output width or height is set.
        // This will enable the option to crop the image based on the provided output width or height.
        if ((cropMode === 'fixed' || cropMode === 'ratioBased' || cropMode === 'free') && ((outputWidth && !outputHeight) || (!outputWidth && outputHeight))) {
            config.size.locked = false; // Set config  lock to false, in order for the fixed mode to work with aspect ratios.
        }
    }, []);

    if (componentKey === 'outpaint') {
        return (
            <OutpaintEditorCanvas
                outputWidth={outputWidth}
                outputHeight={outputHeight}
                maxOutputWidth={maxOutputWidth}
                maxOutputHeight={maxOutputHeight}
                imageFormat={imageFormat}
                imageQuality={imageQuality}
                image={image}
                onChange={onOutpaintChange}
                outpaintMode={outpaintMode}
            />
        );
    }
    return (
        <div className="asset-cropper-view" style={{ width: '100%' }}>
            <div className="asset-cropper-view__content">
                <div className="asset-cropper-view__children">
                    <AssetImageCropper
                        outputWidth={outputWidth}
                        outputHeight={outputHeight}
                        maxOutputWidth={maxOutputWidth}
                        maxOutputHeight={maxOutputHeight}
                        imageFormat={imageFormat}
                        imageQuality={imageQuality}
                        image={image}
                        config={config}
                        onChange={onChange}
                        ratio={ratio}
                        showCrop={true}
                        cropFull={false}
                        locked={locked}
                        imageBoxStyle={imageBoxStyle}
                        reactCropClassName={reactCropClassName}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssetImageCropperView;
