import React, { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from 'components/ui-components-v2/TextField';
import Translation from 'components/data/Translation';
import GenericIcon from 'components/ui-components/GenericIcon';

import './styles/main.scss';

const DEFAULT_TEXT = Translation.get('assetGalleryDialog.assetVideoCropper.selection', 'content-space');

interface Props {
    text?: string;
    width?: number;
    height?: number;
    isDisabled?: boolean;
    showCropLinkIcon?: boolean;
    onWidthChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onHeightChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

/**
 * A row component for selecting the output width and height of a crop.
 * @param width - The width of the crop.
 * @param height - The height of the crop.
 * @param isDisabled - Whether the component is disabled.
 */
const CropInputRow: React.FC<Props> = ({ width, height, isDisabled, onWidthChange, onHeightChange, text, showCropLinkIcon }) => {
    const [widthState, setWidth] = React.useState<number>(0);
    const [heightState, setHeight] = React.useState<number>(0);

    useEffect(() => {
        if (width && widthState !== width) {
            setWidth(width);
        }
        if (height && heightState !== height) {
            setHeight(height);
        }
    }, [width, height]);

    return (
        <div className="crop-input-row">
            <div className="crop-input-row__title">{text ? text : DEFAULT_TEXT}</div>
            <div className="crop-input-row__resolution">
                <TextField
                    className="crop-input-row__resolution__textField"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">px</InputAdornment>
                    }}
                    variant="outlined"
                    type="number"
                    value={widthState}
                    onChange={onWidthChange}
                    disabled={isDisabled}
                />
                {showCropLinkIcon ? (
                    <GenericIcon svgClassName="crop-input-row__resolution__icon" icon={'insert-link'} />
                ) : (
                    <div className="crop-input-row__resolution__icon">x</div>
                )}
                <TextField
                    className="crop-input-row__resolution__textField"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">px</InputAdornment>
                    }}
                    variant="outlined"
                    type="number"
                    value={heightState}
                    onChange={onHeightChange}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
};

export default CropInputRow;
