import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import CropControllerItem from '../interfaces/cropper-controller-item';

import '../styles/cropper-controller-item.scss';

interface Props extends CropControllerItem {
    isSelected: boolean;
    onClick: () => void;
}

/**
 * Renders a single item in the CropperControllerItem list.
 * @param {string} title - The title of the item.
 * @param {string} aspectRatio - The aspect ratio of the item.
 * @param {React.ReactNode} icon - The icon to display for the item.
 * @param {boolean} isSelected - Whether the item is currently selected.
 * @param {Function} onClick - The function to call when the item is clicked.
 */
const CropperControllerItem: React.FC<Props> = ({ title, aspectRatio, icon, isSelected, onClick }) => {
    return (
        <Button variant="outlined" className={`cropper-controller-item ${isSelected && 'cropper-controller-item-selected'}`} onClick={() => onClick()}>
            <Icon>{icon}</Icon>
            <div className="cropper-controller-item__aspectRatio">{title ? title : aspectRatio}</div>
        </Button>
    );
};

export default CropperControllerItem;
