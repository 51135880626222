import React from 'react';
import Slider, { SliderProps } from 'components/ui-components-v2/Slider';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';

interface ProgressBarState {
    progressBar?: number;
}

type SliderPropsWithoutValue = Omit<SliderProps, 'value'>;

interface Props extends SliderPropsWithoutValue {
    onValueChange: (value: number) => void;
}

/**
 * This component is used to display the progress bar of the video cropper.
 * The responsibility is the listen to the progress bar state and update the progress bar accordingly.
 * This component was created in order to separate the progress bar state from the video cropper component, this prevent re-rendering of the video cropper component.
 */
const VideoCropperProgressBar: React.FC<Props> = (props) => {
    const { progressBar = 0 } = useComponentStore<ProgressBarState>('AssetEditor', {
        fields: { progressBar: 'videoCropperState.progressBar' }
    });

    return <Slider {...props} value={progressBar} />;
};

export default VideoCropperProgressBar;
