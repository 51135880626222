import Translation from 'components/data/Translation';
import CropControllerItem from '../interfaces/cropper-controller-item';

const cropperControllerItems: CropControllerItem[] = [
    {
        title: Translation.get('assetGalleryCropper.original', 'content-space'),
        aspectRatio: '0',
        icon: 'fullscreen'
    },
    {
        aspectRatio: '1:1',
        icon: 'crop_square'
    },
    {
        aspectRatio: '4:3',
        icon: 'crop_landscape'
    },
    {
        aspectRatio: '3:4',
        icon: 'crop_portrait'
    },
    {
        aspectRatio: '16:9',
        icon: 'crop_16_9'
    },
    {
        aspectRatio: '9:16',
        icon: 'crop_portrait'
    },
    {
        aspectRatio: '4:5',
        icon: 'crop_portrait'
    },
    {
        aspectRatio: '5:4',
        icon: 'crop_landscape'
    },
    {
        aspectRatio: '6:4',
        icon: 'crop_landscape'
    }
];

export default cropperControllerItems;
