import React, { PropsWithChildren } from 'react';
import Accordion from 'components/ui-components/Accordion';

import '../styles/accordion-row.scss';

interface Props {
    title: string;
    defaultExpanded?: boolean;
}

/**
 * A custom accordion row component responsible for rendering an accordion row with a title and content.
 * @param title - The title of the accordion row
 * @param children - The content to be displayed when the accordion row is expanded
 * @param defaultExpanded - Whether the accordion row should be expanded by default
 */
const AccordionRow: React.FC<PropsWithChildren<Props>> = ({ title, children, defaultExpanded }) => {
    return (
        <Accordion
            className={`accordion-row}`}
            defaultExpanded={defaultExpanded}
            classes={{ root: 'accordion-root', expanded: 'accordion-expanded' }}
            TransitionProps={{ unmountOnExit: true, timeout: 0 }}
            accordionDetailsProps={{ className: 'accordion-row__details' }}
            accordionSummaryProps={{
                classes: { root: 'accordion-row__summary', contentGutters: 'content-gutters' }
            }}
            accordionSummaryTitle={title}>
            {children}
        </Accordion>
    );
};

export default AccordionRow;
