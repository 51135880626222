import Typography from '@mui/material/Typography';
import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';

interface AssetVideoTimeState {
    progressBar?: number;
}

interface Props {
    className?: string;
    maxTime: number;
}

/**
 * Responsible for displaying the current time of the video.
 * This was created to avoid re-rending the video cropper component, now when the progress bar changes, only this component will re-render.
 */
const AssetVideoTime: React.FC<Props> = ({ className, maxTime }) => {
    const { progressBar = 0 } = useComponentStore<AssetVideoTimeState>('AssetEditor', {
        fields: { progressBar: 'videoCropperState.progressBar' }
    });

    return (
        <div className={className}>
            <Typography variant="body2">{`${AECropperHelper.getCurrentTime(progressBar, maxTime)} / ${AECropperHelper.sec2Time(maxTime)}`}</Typography>
        </div>
    );
};

export default AssetVideoTime;
