import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ManualResolutionInput, VideoCropData } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';

import '../styles/asset-video-cropper-overlay.scss';

interface Props {
    children: ReactElement;
    ratio: number;
    setData: (data: VideoCropData) => void;
    canCrop: boolean;
    outputWidth: number;
    outputHeight: number;
    cropData: VideoCropData;
    manualInput: ManualResolutionInput;
    cropMode: string;
}

/**
 * Cropping overlay component for cropping a video asset.
 * @param {Object} props - Component props.
 * @param {ReactNode} props.children - Child components.
 * @param {number} props.ratio - Aspect ratio of the crop box.
 * @param {function} props.setData - Function to set the crop data.
 * @param {boolean} props.canCrop - Whether the asset can be cropped.
 * @param {number} props.outputWidth - Width of the output video.
 * @param {number} props.outputHeight - Height of the output video.
 * @param {Object} props.cropData - Data for the crop box.
 * @param {boolean} props.manualInput - Whether manual input is allowed.
 * @param {string} props.cropMode - Mode for cropping the video.
 */
const AssetVideoCropperOverlay: React.FC<Props> = ({ children, ratio, setData, canCrop, outputWidth, outputHeight, cropData, manualInput, cropMode }) => {
    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [size, setSize] = useState({ w: 0, h: 0 });
    const [moveActive, setMoveActive] = useState(false);
    const [scaleActive, setScaleActive] = useState(false);
    const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
    const [startPos, setStartPos] = useState({ ...pos });
    const [startSize, setStartSize] = useState({ ...size });
    const [dir, setDir] = useState(undefined);
    const [isVideoReady, setIsVideoReady] = useState(false);

    const mediaContainerRef = useRef<HTMLDivElement>(null);
    const cropWrapperRef = useRef<HTMLDivElement>(null);
    const cropSelectionRef = useRef({ x: pos.x, y: pos.y, w: size.w, h: size.h }); // Keep track of crop selection dimensions as a ref.
    const prevVideoClientSize = useRef({ w: 0, h: 0 }); // Keep track of previous video client size.
    const isVideoElementInitialized = useRef(false); // Keep track of whether the video element has loaded.

    /**
     * Update the crop selection dimensions ref.
     */
    const updateCropSelectionRef = () => {
        Object.assign(cropSelectionRef.current, {
            w: size.w,
            h: size.h,
            x: pos.x,
            y: pos.y
        });
    };

    updateCropSelectionRef();

    // activate 'Move selection' and save start position
    const onStart = (e) => {
        setMoveActive(true);
        setMouseStart({ x: e.clientX, y: e.clientY });
        setStartPos(pos);
    };

    // activate 'Scale selection' and save start position
    const onResize = (e) => {
        setDir(e.target.getAttribute('dir'));
        setScaleActive(true);
        setMouseStart({ x: e.clientX, y: e.clientY });
        setStartPos(pos);
        setStartSize(size);
    };

    const onMouseMove = (e) => {
        if (!mediaContainerRef.current) {
            return;
        }

        const mediaEl = mediaContainerRef.current.children[0];
        const minimumSize = 50;

        // transformations when user scales selection
        if (scaleActive) {
            let h = size.h,
                w = size.w,
                x = pos.x,
                y = pos.y;

            // transformations when ratio is selected
            if (ratio || (cropMode === 'sizeBased' && outputWidth && outputHeight)) {
                if (!ratio) ratio = outputWidth / outputHeight;
                if (dir === 'nw') {
                    y = startPos.y + (e.clientX - mouseStart.x) / ratio;
                    x = startPos.x + (e.clientX - mouseStart.x);
                    w = startSize.w - (e.clientX - mouseStart.x);
                    h = startSize.h - (e.clientX - mouseStart.x) / ratio;
                } else if (dir === 'ne') {
                    w = startSize.w + (e.clientX - mouseStart.x);
                    y = startPos.y - (e.clientX - mouseStart.x) / ratio;
                    h = startSize.h + (e.clientX - mouseStart.x) / ratio;
                } else if (dir === 'se') {
                    w = startSize.w + (e.clientX - mouseStart.x);
                    h = startSize.h + (e.clientX - mouseStart.x) / ratio;
                } else if (dir === 'sw') {
                    x = startPos.x + (e.clientX - mouseStart.x);
                    h = startSize.h - (e.clientX - mouseStart.x) / ratio;
                    w = startSize.w - (e.clientX - mouseStart.x);
                }

                // Free transform selection
            } else {
                if (dir === 's' || dir === 'se' || dir === 'sw') {
                    h = startSize.h + (e.clientY - mouseStart.y);
                    if (y + h > mediaEl.clientHeight) h = mediaEl.clientHeight - y;
                }
                if (dir === 'e' || dir === 'ne' || dir === 'se') {
                    w = startSize.w + (e.clientX - mouseStart.x);
                    if (x + w > mediaEl.clientWidth) w = mediaEl.clientWidth - x;
                }
                if (dir === 'w' || dir === 'nw' || dir === 'sw') {
                    x = startPos.x + (e.clientX - mouseStart.x);
                    if (x < 0) {
                        x = 0;
                        return;
                    }
                    w = startSize.w - (e.clientX - mouseStart.x);
                }
                if (dir === 'n' || dir === 'nw' || dir === 'ne') {
                    y = startPos.y + (e.clientY - mouseStart.y);
                    if (y < 0) {
                        y = 0;
                        return;
                    }
                    h = startSize.h - (e.clientY - mouseStart.y);
                }
            }

            // Check if the selection box matches the requirements
            if (y < 0 || x < 0 || x + w > mediaEl.clientWidth || y + h > mediaEl.clientHeight || w < minimumSize || h < minimumSize) return;

            // set new position and size
            setSize({ w, h });
            setPos({ x, y });
        }

        // transformations when user moves the selection
        else if (moveActive) {
            let x = startPos.x + (e.clientX - mouseStart.x);
            let y = startPos.y + (e.clientY - mouseStart.y);

            if (x < 0) x = 0;
            if (x + size.w > mediaEl.clientWidth) x = mediaEl.clientWidth - size.w;

            if (y < 0) y = 0;
            if (y + size.h > mediaEl.clientHeight) y = mediaEl.clientHeight - size.h;

            setPos({ x, y });
        }
    };

    // when user stops dragging the selection
    const stopInteraction = () => {
        if (!mediaContainerRef.current) {
            return;
        }

        const mediaEl = mediaContainerRef.current.children[0] as HTMLVideoElement;

        const originalVideo = {
            w: mediaEl.videoWidth,
            h: mediaEl.videoHeight
        };

        const screenVideo = {
            w: mediaEl.clientWidth,
            h: mediaEl.clientHeight
        };

        setData({
            x: Math.round((pos.x / screenVideo.w) * originalVideo.w),
            y: Math.round((pos.y / screenVideo.h) * originalVideo.h),
            w: Math.round((size.w / screenVideo.w) * originalVideo.w),
            h: Math.round((size.h / screenVideo.h) * originalVideo.h)
        });

        setMoveActive(false);
        setScaleActive(false);
        setDir(undefined);
    };

    // show the selection box and give the container the size of asset.
    const showSelectBox = () => {
        if (!mediaContainerRef.current || !cropWrapperRef.current) {
            return;
        }

        const mediaSize = {
            w: mediaContainerRef.current.children[0].clientWidth,
            h: mediaContainerRef.current.children[0].clientHeight
        };

        cropWrapperRef.current.style.display = 'block';
        cropWrapperRef.current.style.width = mediaSize.w + 'px';
        cropWrapperRef.current.style.height = mediaSize.h + 'px';
    };

    // Draw the INITIAL Selection box over the asset.
    const drawSelectBox = () => {
        if (!mediaContainerRef.current) {
            return;
        }

        const mediaEl = mediaContainerRef.current.children[0] as HTMLVideoElement;

        const mediaSize = {
            w: mediaEl.clientWidth,
            h: mediaEl.clientHeight
        };

        const originalVideoSize = {
            w: mediaEl.videoWidth,
            h: mediaEl.videoHeight
        };

        let data;

        // We already have a ratio set, use that ratio
        if ((ratio && !cropData.w && !cropData.h) || (ratio && Math.floor((cropData.w / cropData.h) * 100) !== Math.floor(ratio * 100))) {
            // We have a horizontal video
            if (mediaSize.w / mediaSize.h < ratio) {
                data = {
                    w: mediaSize.w,
                    h: mediaSize.w / ratio,
                    x: 0,
                    y: (mediaSize.h - mediaSize.w / ratio) / 2
                };
            }
            // We have a vertical video
            else {
                data = {
                    w: mediaSize.h * ratio,
                    h: mediaSize.h,
                    x: (mediaSize.w - mediaSize.h * ratio) / 2,
                    y: 0
                };
            }
        } else if (outputWidth && outputHeight) {
            data = {
                x: Math.round(0),
                y: Math.round(0),
                w: Math.round((outputWidth / originalVideoSize.w) * mediaSize.w),
                h: Math.round((outputHeight / originalVideoSize.h) * mediaSize.h)
            };
        }
        // We already have a crop set, change setup
        else if (cropData && cropData.w && cropData.h) {
            data = {
                x: Math.round((cropData.x / originalVideoSize.w) * mediaSize.w),
                y: Math.round((cropData.y / originalVideoSize.h) * mediaSize.h),
                w: Math.round((cropData.w / originalVideoSize.w) * mediaSize.w),
                h: Math.round((cropData.h / originalVideoSize.h) * mediaSize.h)
            };
        }
        // Just show the full video
        else {
            data = {
                w: mediaSize.w,
                h: mediaSize.h,
                x: 0,
                y: 0
            };
        }

        setPos({
            x: data.x,
            y: data.y
        });
        setSize({
            w: data.w,
            h: data.h
        });
        setData({
            x: Math.round((data.x / mediaSize.w) * originalVideoSize.w),
            y: Math.round((data.y / mediaSize.h) * originalVideoSize.h),
            w: Math.round((data.w / mediaSize.w) * originalVideoSize.w),
            h: Math.round((data.h / mediaSize.h) * originalVideoSize.h)
        });
    };

    /**
     * Event handler for video load.
     * Sets isVideoElementInitialized to true if the video element has loaded.
     */
    const handleVideoLoad = () => {
        // Set isVideoElementInitialized to true if the video element has loaded.
        isVideoElementInitialized.current = true;
    };

    /**
     * Calculates updated crop data based on new dimensions.
     * @param contentWidth - New width of the video content.
     * @param contentHeight - New height of the video content.
     * @returns Updated crop data.
     */
    const calculateUpdatedCropData = (contentWidth: number, contentHeight: number): VideoCropData => {
        const { w: prevCropWidth, h: prevCropHeight, x: prevCropX, y: prevCropY } = cropSelectionRef.current;
        const { w: prevContentWidth, h: prevContentHeight } = prevVideoClientSize.current;

        const x = Math.round((prevCropX * contentWidth) / prevContentWidth);
        const y = Math.round((prevCropY * contentHeight) / prevContentHeight);
        const width = Math.round((prevCropWidth * contentWidth) / prevContentWidth);
        const height = Math.round((prevCropHeight * contentHeight) / prevContentHeight);

        return { x, y, w: width, h: height };
    };

    /**
     * Handles resizing based on new dimensions.
     * @param contentWidth - New width of the video content.
     * @param contentHeight - New height of the video content.
     */
    const handleResize = (contentWidth: number, contentHeight: number) => {
        // Resize the crop selection only if the video element has been loaded.
        if (!isVideoElementInitialized.current) {
            return;
        }

        // Resize the crop selection only if the previous video client size is set, this is to avoid resizing the crop selection to 0, 0.
        if (!prevVideoClientSize.current.w && !prevVideoClientSize.current.h) {
            prevVideoClientSize.current = { w: contentWidth, h: contentHeight };
            return;
        }

        // Resize the crop selection only if the crop wrapper ref and media container ref are set.
        if (!mediaContainerRef.current || !cropWrapperRef.current) {
            return;
        }

        // Recalculate crop selection based on new dimensions
        const updatedCropData = calculateUpdatedCropData(contentWidth, contentHeight);

        // Update crop selection
        setPos({ x: updatedCropData.x, y: updatedCropData.y });
        setSize({ w: updatedCropData.w, h: updatedCropData.h });

        // Update cropWrapper dimensions based on new video element dimensions.
        cropWrapperRef.current.style.display = 'block';
        cropWrapperRef.current.style.width = contentWidth + 'px';
        cropWrapperRef.current.style.height = contentHeight + 'px';

        // Update previous media size.
        prevVideoClientSize.current = { w: contentWidth, h: contentHeight };
    };

    useEffect(() => {
        size.w && size.h && drawSelectBox();
    }, [ratio]);

    useEffect(() => {
        if (isVideoReady && (manualInput.w !== size.w || manualInput.h !== size.h)) {
            if (!mediaContainerRef.current) {
                return;
            }

            const mediaEl: any = mediaContainerRef.current.children[0];

            const mediaSize = {
                w: mediaEl.clientWidth,
                h: mediaEl.clientHeight
            };

            const originalVideoSize = {
                w: mediaEl.videoWidth,
                h: mediaEl.videoHeight
            };

            setSize({
                w: (manualInput.w / originalVideoSize.w) * mediaSize.w,
                h: (manualInput.h / originalVideoSize.h) * mediaSize.h
            });
            if (pos.x < 0 || pos.y > mediaEl.h) {
                setPos({ x: 0, y: 0 });
            }
        }
    }, [isVideoReady, manualInput]);

    useEffect(() => {
        document.body.addEventListener('mouseup', stopInteraction);
        document.body.addEventListener('mousemove', onMouseMove);
        return () => {
            document.body.removeEventListener('mouseup', stopInteraction);
            document.body.removeEventListener('mousemove', onMouseMove);
        };
    }, [pos, size]);

    useMemo(() => {
        const videoElement = mediaContainerRef.current?.children[0];

        if (videoElement) {
            videoElement.addEventListener('loadeddata', handleVideoLoad); // Add event listener to the video element to check if the video element has loaded.

            // Create a ResizeObserver and attach it to the video element
            const resizeObserverCallback: ResizeObserverCallback = () => {
                const { clientWidth, clientHeight } = videoElement;
                handleResize(clientWidth, clientHeight); // Handle crop selection and container resizing based on the new video element dimensions.
            };

            const resizeObserver = new ResizeObserver(resizeObserverCallback);
            resizeObserver.observe(videoElement); // Observe the video element for changes in dimensions.

            return () => {
                // Cleanup: Remove event listener and disconnect the ResizeObserver when the component is unmounted
                videoElement.removeEventListener('loadeddata', handleVideoLoad);
                resizeObserver.disconnect();
            };
        }
    }, [mediaContainerRef.current?.children.length]);

    return (
        <div className={classNames('cropper', ((outputWidth && outputHeight) || !canCrop) && 'noCrop')} onMouseMove={onMouseMove}>
            <div className="cropper__container">
                <div ref={mediaContainerRef} className="cropper__container__media">
                    {React.cloneElement(children, {
                        onCanPlayThrough: () => {
                            if (canCrop && !isVideoReady) {
                                showSelectBox();
                                drawSelectBox();
                                setIsVideoReady(true);
                            }
                        }
                    })}
                </div>
                {canCrop && (
                    <div className="cropper__container__selection" ref={cropWrapperRef}>
                        <div
                            className="select-box"
                            draggable={false}
                            id="move"
                            style={{ left: pos.x, top: pos.y, width: size.w, height: size.h }}
                            onMouseDown={onStart}>
                            {!outputWidth && !outputHeight && (
                                <div className="drag-elements" onMouseDown={onResize}>
                                    {((!cropMode && !ratio) || (cropMode === 'free' && ratio === 0)) && (
                                        <React.Fragment>
                                            <div draggable={false} className="drag-box n" dir="n" />
                                            <div draggable={false} className="drag-box e" dir="e" />
                                            <div draggable={false} className="drag-box s" dir="s" />
                                            <div draggable={false} className="drag-box w" dir="w" />
                                        </React.Fragment>
                                    )}
                                    <div draggable={false} className="drag-box ne" dir="ne" />
                                    <div draggable={false} className="drag-box se" dir="se" />
                                    <div draggable={false} className="drag-box sw" dir="sw" />
                                    <div draggable={false} className="drag-box nw" dir="nw" />
                                </div>
                            )}
                            <div draggable={false} className="rule-of-thirds hz" />
                            <div draggable={false} className="rule-of-thirds vt" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AssetVideoCropperOverlay;
