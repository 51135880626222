/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import AssetEditorImage from '../../../../AssetEditor/components/AssetEditorImage';

import 'react-image-crop/dist/ReactCrop.css';
import './../styles/asset-image-cropper.scss';

/**
 * This is the image cropper that allows you to scale the image
 */
const Cropper = ({
    image,
    config,
    onChange,
    outputWidth,
    outputHeight,
    maxOutputWidth,
    maxOutputHeight,
    imageFormat,
    imageQuality,
    ratio,
    showCrop,
    cropFull,
    locked,
    imageBoxStyle,
    reactCropClassName
}) => {
    const imgRef = useRef(null);
    const [box, _setBox] = useState({});
    const [crop, _setCrop] = useState({ unit: '%' });
    const [aspect, _setAspect] = useState(ratio);
    const [newSrc, _setNewSrc] = useState('');

    const { manualInput } = useComponentStore('ImageCropper', {
        fields: {
            manualInput: 'manualInput'
        }
    });

    const { originalHeight, originalWidth } = useComponentStore('AssetEditor', {
        fields: {
            originalHeight: 'assetData.height',
            originalWidth: 'assetData.width'
        }
    });

    /**
     * If the ratio changes, calculate the new crop.
     */
    useEffect(() => {
        if (ratio === null || ratio === undefined) return;

        let imageAspectRatio = ratio;

        if (ratio === 0) {
            imageAspectRatio = imgRef.current.width / imgRef.current.height; // Set full image aspect ratio, if ratio is 0, this will make the crop freeForm.
        }

        _setAspect(imageAspectRatio);
        makeClientCrop(centerRatioCrop(imgRef.current.width, imgRef.current.height, imageAspectRatio));
        setCrop(centerRatioCrop(imgRef.current.width, imgRef.current.height, imageAspectRatio));
    }, [ratio]);

    const setCrop = (percentageCrop) => {
        _setCrop(percentageCrop);
    };
    /**
     * Set the bounding box to a specific size
     */
    const setBox = (el) => {
        if (!el) return;
        const boxSizes = {
            w: el.getBoundingClientRect().width,
            h: el.getBoundingClientRect().height
        };

        const imageSizes = {
            w: imgRef.current.naturalWidth,
            h: imgRef.current.naturalHeight
        };
        boxSizes.aspect = boxSizes.w / boxSizes.h;
        imageSizes.aspect = imageSizes.w / imageSizes.h;
        let width = '100%';
        if (imageSizes.aspect < boxSizes.aspect && imageSizes.h > boxSizes.h) {
            const percentage = 100 * (imageSizes.aspect / boxSizes.aspect);
            width = percentage + '%';
        }
        _setBox({ width });
    };

    /**
     * Calculates the center point based on the width, height and ratio of the image.
     * @param {number} imageWidth - Image width
     * @param {number} imageHeight - Image height
     * @param {number} ratio - Aspect ratio (Ex. 16 / 9)
     * @returns {object} - New crop for the React cropper.
     */
    const centerRatioCrop = (imageWidth, imageHeight, ratio) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100
                },
                ratio,
                imageWidth,
                imageHeight
            ),
            imageWidth,
            imageHeight
        );
    };

    /**
     * On loading the image, we start cropping the image. This will be in a specific aspect ratio.
     */
    const onLoad = useCallback(
        (img) => {
            imgRef.current = img;

            if (image.crop) {
                //If previous crop exists
                const aspectWidth = maxOutputWidth ? maxOutputWidth : outputWidth;
                const aspectHeight = maxOutputHeight ? maxOutputHeight : outputHeight;
                const precision = 10; // Define a precision value for rounding.

                const cropAspectCorrect = ratio
                    ? // If 'ratio' is truthy, calculate and round it with the defined precision.
                      Math.round(ratio * precision) / precision
                    : // If 'ratio' is falsy, calculate and round the aspect ratio with the defined precision.
                      Math.round((aspectWidth / aspectHeight) * precision) / precision;

                let canInitCrop = false;

                // Check if the aspect ratio of the crop is the same as the aspect ratio of the output width and output height.
                // These can be different when using overwrites or choosing a different format (1:1 to 4:5)
                if (image.crop.unit === '%') {
                    const percentage = 100; // Define a variable for the percentage value

                    // Calculate the aspect width in percentages
                    const aspectWidthPercentages = img.width * (image.crop.width / percentage);
                    // Calculate the aspect height in percentages
                    const aspectHeightPercentages = img.height * (image.crop.height / percentage);

                    const cropAspectPercentages = Math.round((aspectWidthPercentages / aspectHeightPercentages) * precision) / precision;

                    if (cropAspectPercentages === cropAspectCorrect) {
                        canInitCrop = true;
                    }
                } else if (image.crop.unit === 'px') {
                    const cropAspectPx = Math.round((image.crop.width / image.crop.height) * precision) / precision;

                    if (cropAspectPx === cropAspectCorrect) {
                        canInitCrop = true;
                    }
                }

                if (config.size.locked === false) {
                    canInitCrop = true;
                }

                if (canInitCrop === true) {
                    _setAspect(cropAspectCorrect);
                    makeClientCrop(image.crop);
                    return setCrop(image.crop);
                }
            }

            const imageAspect = img.width / img.height;
            let cropperAspect = false;

            //define cropper aspect
            if (config.size.startFull) {
                cropperAspect = imageAspect;
            } else if ((config.size.width || maxOutputWidth) && (config.size.height || maxOutputHeight)) {
                cropperAspect = (config.size.width || maxOutputWidth) / (config.size.height || maxOutputHeight);
            } else if (ratio) {
                cropperAspect = ratio;
            } else {
                cropperAspect = imageAspect;
            }

            _setAspect(cropperAspect);
            makeClientCrop(centerRatioCrop(img.width, img.height, cropperAspect));
            return setCrop(centerRatioCrop(img.width, img.height, cropperAspect));
        },
        [ratio, newSrc, cropFull]
    );

    /**
     * When creating a crop, we start the preview
     * @param {*} crop
     */
    const makeClientCrop = async (crop) => {
        if (imgRef.current && crop.width !== null && crop.height !== null) {
            return await createCropPreview(imgRef.current, crop);
        }
    };

    /**
     * Create a crop in the relative size.
     * This is used when we use percentage cropping.
     * @param {*} imageRef
     * @param {*} crop
     * @param {*} config
     */
    const cropRelativeSize = async (imageRef, crop, config, flip) => {
        // The promise is needed for the first load, because our assetSelector changes the size of the image to fit the dialog
        // Therefore it waits for 30ms to find the new actual size of the image
        // This is needed if the crop unit is %
        const promise = await new Promise((resolve) => {
            setTimeout(() => {
                const updatedWidth = document.getElementsByClassName('asset-image-cropper__image')[0]?.clientWidth;
                const updatedHeight = document.getElementsByClassName('asset-image-cropper__image')[0]?.clientHeight;

                const canvas = document.createElement('canvas');

                const scaleX = imageRef.naturalWidth / updatedWidth;
                const scaleY = imageRef.naturalHeight / updatedHeight;
                if (cropFull) {
                    crop = {
                        unit: '%',
                        width: 100,
                        height: 100,
                        x: 0,
                        y: 0
                    };
                }
                if (crop.unit === '%') {
                    crop.width = (crop.width / 100) * updatedWidth;
                    crop.height = (crop.height / 100) * updatedHeight;
                    crop.x = (crop.x / 100) * updatedWidth;
                    crop.y = (crop.y / 100) * updatedHeight;
                    crop.unit = 'px';
                }

                const sx = crop.x * scaleX,
                    sy = crop.y * scaleY,
                    sWidth = crop.width * scaleX,
                    sHeight = crop.height * scaleY;

                let outputWidth = sWidth;
                let outputHeight = sHeight;

                const imageAspect = imageRef.naturalWidth / imageRef.naturalHeight;

                //Calculate the size of the crop
                if (config.size.locked) {
                    // Locked aspect ratio scenario.
                    if (config.size.height === 0 && config.size.width === 0 && maxOutputHeight === 0 && maxOutputWidth === 0) {
                        // No sizes are given, use image ratio.
                        config.size.fixed = false;
                    } else if (config.size.height === 0 && maxOutputHeight === 0) {
                        // Only width is given, use this to determine height based on the image aspect ratio.
                        config.size.height = config.size.width / imageAspect;
                        config.size.fixed = true;
                    } else if (config.size.width === 0 && maxOutputWidth === 0) {
                        // Only height is given, use this to determine width based on the image aspect ratio.
                        config.size.width = config.size.height * imageAspect;
                        config.size.fixed = true;
                    } else {
                        // Normal scenario, aspect ratio is set based on specified width and height.
                        if (maxOutputWidth && maxOutputHeight) {
                            config.size.fixed = false;
                        } else {
                            config.size.fixed = true;
                        }
                    }
                } else {
                    // Free aspect ratio scenario.
                    if (config.size.width !== 0 || config.size.height !== 0) {
                        // If a width is set, use fixed size. Derive the fixed hight from the crop aspect ratio.
                        config.size.fixed = true;
                    }
                }

                //Calculate the size of the output
                if (config.size.fixed) {
                    if (config.size.locked) {
                        // If aspect ratio is locked, we allready know both sizes.
                        outputWidth = config.size.width;
                        outputHeight = config.size.height;
                    } else {
                        if (config.size.width > 0) {
                            outputWidth = config.size.width;
                            outputHeight = (config.size.width / crop.width) * crop.height;
                        } else if (config.size.height > 0) {
                            outputHeight = config.size.height;
                            outputWidth = (config.size.height / crop.height) * crop.width;
                        }
                    }
                }
                const outputAspect = outputWidth / outputHeight;

                //If there is max output height or width check and scale both width and height accordingly capped at lowest value
                const bothMax = maxOutputHeight && maxOutputWidth ? true : false;

                if (((bothMax && outputWidth <= outputHeight) || (!bothMax && maxOutputHeight > 0)) && outputHeight > maxOutputHeight) {
                    canvas.height = maxOutputHeight;
                    canvas.width = maxOutputHeight * outputAspect;
                    outputHeight = maxOutputHeight;
                    outputWidth = maxOutputHeight * outputAspect;
                } else if (((bothMax && outputHeight < outputWidth) || (!bothMax && maxOutputWidth > 0)) && outputWidth > maxOutputWidth) {
                    canvas.height = maxOutputWidth / outputAspect;
                    canvas.width = maxOutputWidth;
                    outputHeight = maxOutputWidth / outputAspect;
                    outputWidth = maxOutputWidth;
                } else {
                    canvas.height = outputHeight;
                    canvas.width = outputWidth;
                }

                if (cropFull) {
                    outputWidth = imageRef.naturalWidth;
                    outputHeight = imageRef.naturalHeight;
                    canvas.width = imageRef.naturalWidth;
                    canvas.height = imageRef.naturalHeight;
                }

                const ctx = canvas.getContext('2d');

                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'low';

                let extension = (() => {
                    if (imageFormat) {
                        return imageFormat;
                    }

                    if (image.extension) {
                        return image.extension;
                    }

                    return 'jpeg';
                })();

                // canvas.toDataURL can only handle 'jpeg'
                if (extension === 'jpg') extension = 'jpeg';

                if (flip) {
                    canvas.width = imgRef.current.naturalWidth;
                    canvas.height = imgRef.current.naturalHeight;
                    ctx.translate(imgRef.current.naturalWidth, 0);
                    ctx.beginPath();
                    ctx.scale(-1, 1);
                    ctx.drawImage(imgRef.current, 0, 0);
                    _setNewSrc(canvas.toDataURL(`image/${extension}`, imageQuality));
                } else {
                    ctx.drawImage(imageRef, sx, sy, sWidth, sHeight, 0, 0, outputWidth, outputHeight);
                }

                resolve({ crop, width: outputWidth, height: outputHeight });
            }, 0);
        });
        return promise;
    };

    /**
     * We start creating the preview of the crop.
     */
    const createCropPreview = async (image, crop, flip) => {
        const cropData = await cropRelativeSize(image, crop, config, flip);
        if (cropData.crop.unit === 'px') {
            //Convert from px to % to save
            const updatedWidth = document.getElementsByClassName('asset-image-cropper__image')[0]?.clientWidth;
            const updatedHeight = document.getElementsByClassName('asset-image-cropper__image')[0]?.clientHeight;
            cropData.crop.aspect = Math.round((cropData.crop.width / cropData.crop.height) * 100) / 100;
            cropData.crop.width = (cropData.crop.width / updatedWidth) * 100;
            cropData.crop.height = (cropData.crop.height / updatedHeight) * 100;
            cropData.crop.x = (cropData.crop.x / updatedWidth) * 100;
            cropData.crop.y = (cropData.crop.y / updatedHeight) * 100;
            cropData.crop.unit = '%';
        }

        onChange(cropData);
        return cropData;
    };

    const src = (() => {
        if (newSrc) return newSrc;
        if (image.editedOriginalImage) return image.editedOriginalImage;
        if (image.originalImage) return image.originalImage;
        if (image.origin) return image.origin.url;
        return image.url;
    })();

    /**
     * Checks if ratio should be a specific aspect ratio or freeForm.
     * If ratio is equals to 0, then return null for freeForm else return specific aspect ratio.
     */
    const getAspectRatio = () => {
        if (ratio === 0) {
            return null;
        }
        return aspect;
    };

    useMemo(() => {
        if (originalHeight && originalWidth && manualInput) {
            const { width, height } = AECropperHelper.convertPixelsToPercentage(manualInput.w, manualInput.h, originalWidth, originalHeight);
            const newCrop = { ...crop, width, height };

            const { width: cropXInPx, height: cropYInPx } = AECropperHelper.convertPercentageToPixels(newCrop.x, newCrop.y, originalWidth, originalHeight);

            const totalWidth = manualInput.w + cropXInPx; // Calculate the total width of the crop selection including the crop x coordinate.
            const totalHeight = manualInput.h + cropYInPx; // Calculate the total height of the crop selection including the crop y coordinate.

            // Set new crop coordinates if the crop selection is outside the box boundaries.
            if (totalWidth > originalWidth || totalHeight > originalHeight) {
                const newCropXInPx = totalWidth - originalWidth;
                const newCropYInPx = totalHeight - originalHeight;

                const { width: newCropXInPercentage, height: newCropYInPercentage } = AECropperHelper.convertPixelsToPercentage(
                    newCropXInPx,
                    newCropYInPx,
                    originalWidth,
                    originalHeight
                );

                if (totalWidth > originalWidth) {
                    newCrop.x = newCrop.x - newCropXInPercentage; // Update crop x coordinate if it is outside the box boundaries.
                }

                if (totalHeight > originalHeight) {
                    newCrop.y = newCrop.y - newCropYInPercentage; // Update crop y coordinate if it is outside the box boundaries.
                }
            }

            setCrop(newCrop);
            makeClientCrop(newCrop);
        }
    }, [manualInput?.w, manualInput?.h]);

    return (
        // eslint-disable-next-line react/no-unknown-property
        <div className="asset-image-cropper" onLoad={(e) => setBox(e.currentTarget)}>
            <div className="asset-image-cropper__box" style={{ width: box.width }}>
                {showCrop ? (
                    <ReactCrop
                        crop={crop}
                        className={reactCropClassName}
                        ruleOfThirds="true"
                        locked={locked}
                        aspect={getAspectRatio()}
                        onChange={(_, percentageCrop) => setCrop(percentageCrop)}
                        onComplete={makeClientCrop}>
                        <AssetEditorImage
                            imageRef={imgRef}
                            imageSrc={src}
                            mainDivClassName="asset-image-cropper__image"
                            imageBoxStyle={imageBoxStyle}
                            onLoad={(e) => onLoad(e.currentTarget)}
                        />
                    </ReactCrop>
                ) : null}
            </div>
        </div>
    );
};

export default Cropper;
