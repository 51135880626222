import ImageFileService from 'services/image-file/image.service';
import { Crop } from 'react-image-crop';
import { WidthWide } from '@mui/icons-material';
import { ImageCropData, ManualResolutionInput, VideoCropData } from '../interfaces/asset-cropper-state';

/**
 * A helper class for calculating manual input height and width for asset editor cropper.
 */
class AssetEditorCropperHelper {
    /**
     * Calculates the height of an asset based on its width and aspect ratio.
     * If aspect ratio is 0 then the default height is returned.
     * @param width - The width of the asset.
     * @param ratioInDecimals - The aspect ratio of the asset in decimal form (e.g. 0.75 for a 4:3 aspect ratio).
     * @param defaultHeight - The default height of the asset.
     * @returns The calculated height of the asset.
     */
    static calculateManualInputHeight = (width: number, ratioInDecimals: number, defaultHeight: number) => {
        const results = ratioInDecimals ? width / ratioInDecimals : defaultHeight;
        return parseInt(results.toFixed());
    };

    /**
     * Calculates the width of an asset based on its height and aspect ratio.
     * If the aspect ratio is 0 then the default width is returned.
     * @param height The height of the asset.
     * @param ratioInDecimals The aspect ratio of the asset in decimal form.
     * @param defaultWidth The default width of the asset.
     * @returns The calculated width of the asset.
     */
    static calculateManualInputWidth = (height: number, ratioInDecimals: number, defaultWidth: number) => {
        const results = ratioInDecimals ? height * ratioInDecimals : defaultWidth;
        return parseInt(results.toFixed());
    };

    /**
     * Gets the manual input dimensions based on the provided type, value, defaultManualInput, and ratio.
     * @param type - The type of dimension to calculate, either 'width' or 'height'.
     * @param value - The value of the dimension that is comes from the Input component.
     * @param defaultManualInput - The default manual input dimensions to use if there is no value ratio.
     * @param ratio - The ratio of the image to calculate the dimensions for. (e.g. '4:3', '16:9', '1:1')
     * @returns An ManualResolutionInput object containing the calculated width and height dimensions.
     */
    static getManualInput = (type: 'width' | 'height', value: string, defaultManualInput: ManualResolutionInput, ratio: string) => {
        const ratioInDecimals = ImageFileService.calculateImageRatio(ratio);
        const dimension = value !== '' ? parseInt(value) : 0; // Return 0 if value is empty string.

        if (type === 'width') {
            const height = this.calculateManualInputHeight(dimension, ratioInDecimals, defaultManualInput.h); // Handle the height value.
            return { w: dimension, h: height };
        }

        const width = this.calculateManualInputWidth(dimension, ratioInDecimals, defaultManualInput.w); // Handle the width value.

        return { w: width, h: dimension };
    };

    /**
     * Validates the manual input for width and height and returns the new width and height values.
     * @param width - The width value to validate.
     * @param height - The height value to validate.
     * @param originalWidthInPx - The original width of the asset in pixels.
     * @param originalHeightInPx - The original height of the asset in pixels.
     * @returns An object containing the new width and height values.
     */
    static validateManualInput = (width: number, height: number, originalWidthInPx: number, originalHeightInPx: number): ManualResolutionInput => {
        let newWidth = width;
        let newHeight = height;

        // Check if value is higher than the original width.
        if (newWidth > originalWidthInPx) {
            newWidth = originalWidthInPx;
        }

        // Check if value is higher than the original height.
        if (newHeight > originalHeightInPx) {
            newHeight = originalHeightInPx;
        }

        return { w: newWidth, h: newHeight };
    };

    /**
     * Converts the current width and height of an asset in pixels to a percentage based on the original width and height.
     * @param currentWidthInPx - The current width of the asset in pixels.
     * @param currentHeightInPx - The current height of the asset in pixels.
     * @param originalWidthInPx - The original width of the asset in pixels.
     * @param originalHeightInPx - The original height of the asset in pixels.
     * @returns An object containing the width and height of the asset as a percentage based on the original size.
     */
    static convertPixelsToPercentage = (currentWidthInPx: number, currentHeightInPx: number, originalWidthInPx: number, originalHeightInPx: number) => {
        const widthPercentage = (currentWidthInPx * 100) / originalWidthInPx;
        const heightPercentage = (currentHeightInPx * 100) / originalHeightInPx;

        return { width: widthPercentage, height: heightPercentage };
    };

    /**
     * Converts the given width and height percentages to pixels based on the original width and height in pixels.
     * @param widthPercentage The percentage of the original width to convert to pixels.
     * @param heightPercentage The percentage of the original height to convert to pixels.
     * @param originalWidthInPx The original width of the asset in pixels.
     * @param originalHeightInPx The original height of the asset in pixels.
     * @returns An object containing the converted width and height in pixels.
     */
    static convertPercentageToPixels = (widthPercentage: number, heightPercentage: number, originalWidthInPx: number, originalHeightInPx: number) => {
        const widthInPx = parseInt(((originalWidthInPx * widthPercentage) / 100).toFixed(0));
        const heightInPx = parseInt(((originalHeightInPx * heightPercentage) / 100).toFixed(0));

        return { width: widthInPx, height: heightInPx };
    };

    /**
     * Determines whether the given dimensions are within the bounds of the original image, given the crop data.
     * @param width The width of the cropped image.
     * @param height The height of the cropped image.
     * @param originalWidthInPx The width of the original image in pixels.
     * @param originalHeightInPx The height of the original image in pixels.
     * @param cropData The crop data for the image.
     * @returns True if the dimensions are within bounds, false otherwise.
     */
    static isDimensionWithinBounds = (width: number, height: number, originalWidthInPx: number, originalHeightInPx: number) => {
        return width <= originalWidthInPx && height <= originalHeightInPx && width >= 0 && height >= 0;
    };

    /**
     * Determines whether the "Apply" button should be disabled based on the provided crop data.
     * @param cropData The crop data to check.
     * @returns True if the "Apply" button should be disabled, false otherwise.
     */
    static isApplyButtonDisabled = (cropData: Crop | undefined | null | VideoCropData) => {
        if (!cropData) {
            return true;
        }

        if ('w' in cropData) {
            return cropData.w <= 0 || cropData.h <= 0;
        } else {
            return cropData.height <= 0 || cropData.width <= 0;
        }
    };

    /**
     * Seconds to time
     * Converts duration in seconds to a printable string. float (61.0) => string '01:01'
     */
    static sec2Time = (timeInput, showMilli?: boolean, showHours?: boolean) => {
        const timeInSeconds = timeInput;
        const pad = function (num, size) {
                return ('000' + num).slice(size * -1);
            },
            time: any = parseFloat(timeInSeconds).toFixed(3),
            hours = Math.floor(time / 60 / 60),
            minutes = Math.floor(time / 60) % 60,
            seconds = Math.floor(time - minutes * 60),
            milliseconds = time.slice(-3);

        return (showHours ? pad(hours, 2) + ':' : '') + pad(minutes, 2) + ':' + pad(seconds, 2) + (showMilli ? '.' + milliseconds : '');
    };

    /**
     * Calculates the trim duration based on the trim start and trim end values.
     * @param trimStart - The start time of the trim in seconds.
     * @param trimEnd - The end time of the trim in seconds.
     * @returns The trim duration in seconds.
     */
    static getTrimDuration = (trimStart: number, trimEnd: number) => {
        const totalDuration = trimEnd - trimStart;

        return this.sec2Time(totalDuration, true, false);
    };

    /**
     * Makes sure that the trim start and trim end values are within the bounds of the video duration.
     * @param currentTime The current time of the video in seconds.
     * @param maxTime The maximum time of the video in seconds.
     * @returns The current time if it is within the bounds, otherwise the maximum time.
     */
    static getCurrentTime = (currentTime: number, maxTime: number) => {
        if (currentTime > maxTime) {
            return this.sec2Time(maxTime);
        }
        return this.sec2Time(currentTime);
    };
}

export default AssetEditorCropperHelper;
