import React from 'react';
import AssetVideoCropperView from 'components/assets/AssetGalleryCropper/components/video-cropper-wrapper/components/asset-video-cropper-view';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AGDHelper from 'components/assets/AssetGalleryDialog/helpers/asset-gallery-dialog-helper';
import defaultRatios from '../../data/default-ratios';

interface VideoCropperWrapperState {
    value: AssetGalleryDialogState['value'];
    isProcessing: AssetGalleryDialogState['isProcessing'];
    minimumDuration: AssetGalleryDialogState['data']['assetData']['minimumDuration'];
    maximumDuration: AssetGalleryDialogState['data']['assetData']['maximumDuration'];
    duration: AssetGalleryDialogState['data']['assetData']['duration'];
    useCropper: AssetGalleryDialogState['conditionProps']['useCropper'];
    cropMode: AssetGalleryDialogState['config']['cropper']['cropMode'];
    ratios: AssetGalleryDialogState['config']['cropper']['ratios'];
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
}

interface Props {
    croppedVideoUrl?: string;
    showEditControls?: boolean;
}

/**
 * A wrapper component for the AssetVideoCropperView that handles the logic for displaying the video and its edit controls.
 * @param croppedVideoUrl The URL of the cropped video.
 * @param showEditControls A boolean indicating whether to show the edit controls for the video.
 * @param handleProcessing A function that handles the processing of the video.
 */
const VideoCropperWrapper: React.FC<Props> = ({ croppedVideoUrl, showEditControls }) => {
    const {
        value,
        isProcessing,
        minimumDuration = 0,
        maximumDuration = 0,
        duration = 0,
        useCropper = false,
        cropMode = 'ratioBased',
        ratios = [],
        outputWidth,
        outputHeight
    } = useComponentStore<VideoCropperWrapperState>('AssetGalleryDialog', {
        fields: {
            value: 'value',
            isProcessing: 'isProcessing',
            minimumDuration: 'data.assetData.minimumDuration',
            maximumDuration: 'data.assetData.maximumDuration',
            duration: 'data.assetData.duration',
            useCropper: 'conditionProps.useCropper',
            cropMode: 'config.cropper.cropMode',
            ratios: 'config.cropper.ratios',
            outputWidth: 'data.assetData.outputWidth',
            outputHeight: 'data.assetData.outputHeight'
        }
    });

    /**
     * Returns an object with the video URL based on whether the video has been cropped or not.
     */
    const getValue = () => {
        if (croppedVideoUrl) {
            return { ...value, url: croppedVideoUrl }; // Return cropped video url.
        }
        return value; // If cropped video url doesn't exist return original value object.
    };

    /**
     * Returns an updated array of ratios that includes default ratios if they don't already exist.
     */
    const getUpdatedRatios = () => {
        const copyRatios = [...ratios];

        defaultRatios.forEach((ratio) => {
            // Push current default ratio if it doesn't exist in copyRatios.
            if (!copyRatios.includes(ratio)) {
                copyRatios.push(ratio);
            }
        });

        return copyRatios;
    };

    return (
        <AssetVideoCropperView
            video={getValue()}
            isProcessing={isProcessing}
            minimumDuration={minimumDuration}
            maximumDuration={maximumDuration}
            duration={duration}
            useCropper={useCropper}
            cropMode={cropMode}
            ratios={getUpdatedRatios()}
            outputWidth={outputWidth}
            outputHeight={outputHeight}
            showEditControls={showEditControls}
            handleProcessing={AGDHelper.handleProcessing}
        />
    );
};

export default VideoCropperWrapper;
