import React from 'react';
import CropperControllerItem from './components/cropper-controller-item';
import CropControllerItem from './interfaces/cropper-controller-item';

import './styles/main.scss';

interface Props {
    items: CropControllerItem[];
    selectedAspectRatio: string;
    onCropperItemClick: (item: CropControllerItem) => void;
}

/**
 * Renders a list of cropper controller items.
 * @param items - An array of CropperControllerItem objects.
 * @param selectedAspectRatio - The selected aspect ratio.
 * @param onCropperItemClick - A function that is called when a cropper controller item is clicked.
 */
const CropperControllerItemList: React.FC<Props> = ({ items, selectedAspectRatio, onCropperItemClick }) => {
    return (
        <div className="cropper-controller-item-list">
            {items.map((item, index) => {
                return (
                    <CropperControllerItem
                        key={index}
                        {...item}
                        isSelected={selectedAspectRatio === item.aspectRatio}
                        onClick={() => onCropperItemClick(item)}
                    />
                );
            })}
        </div>
    );
};

export default CropperControllerItemList;
