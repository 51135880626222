import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ImageFileService from 'services/image-file/image.service';
import VideoModifierService from 'services/video-modifier/video-modifier.service';
import Dialog from 'components/ui-components-v2/Dialog';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { ManualResolutionInput, TrimData, VideoCropData, VideoCropperState } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';
import VideoCropper from 'components/assets/AssetGalleryCropper/components/video-cropper-wrapper/components/asset-video-cropper';
import OverlayLoadingIndicatorWrapper from 'components/assets/AssetEditor/components/OverlayLoadingIndicator';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';

import '../styles/asset-video-cropper-view.scss';

interface Props {
    video: any; // Same as AssetGalleryDialog's AssetGallery value prop.
    isProcessing: boolean; // Keeps track of the cropping state.
    handleProcessing: (value) => void; // Sets if cropping is finished or not.
    minimumDuration: number; // number (seconds)
    maximumDuration: number; // number (seconds)
    duration: number | string; // number (seconds)
    useCropper: boolean; // boolean
    cropMode: 'ratioBased' | 'sizeBased' | 'free' | string; // string (ratioBased, sizeBased)
    ratios: string[]; // array (e.d. [1:1, 16:9, 2:1])
    outputWidth: number; // number (pixels)
    outputHeight: number; // number (pixels)
    showEditControls?: boolean; // Disables or enables video edit controls. e.g trim controls.
}
/**
 * AssetVideoCropperView component displays the video cropper UI and handles video cropping.
 * @param {Object} video - The video object to be cropped.
 * @param {boolean} isProcessing - Keeps track of the cropping state.
 * @param {Function} handleProcessing - Sets if cropping is finished or not.
 * @param {number} minimumDuration - The minimum duration of the cropped video in seconds.
 * @param {number} maximumDuration - The maximum duration of the cropped video in seconds.
 * @param {number} duration - The duration of the original video in seconds.
 * @param {boolean} useCropper - Determines if the cropper is used or not.
 * @param {string} cropMode - The crop mode to be used. Can be 'ratioBased' or 'sizeBased'.
 * @param {Array} ratios - The aspect ratios to be used for the cropper.
 * @param {number} outputWidth - The output width of the cropped video in pixels.
 * @param {number} outputHeight - The output height of the cropped video in pixels.
 * @param {boolean} showEditControls - Disables or enables video edit controls. e.g trim controls.
 */
const AssetVideoCropperView: React.FC<Props> = ({
    video, // Same as AssetGalleryDialog's AssetGallery value prop.
    isProcessing, // Keeps track of the cropping state.
    handleProcessing, // Sets if cropping is finished or not.
    minimumDuration, // number (seconds)
    maximumDuration, // number (seconds)
    duration, // number (seconds)
    useCropper, // boolean
    cropMode, // string (ratioBased, sizeBased)
    ratios, // array (e.d. [1:1, 16:9, 2:1])
    outputWidth, // number (pixels)
    outputHeight, // number (pixels)
    showEditControls // Disables or enables video edit controls. e.g trim controls.
}) => {
    const [isWarningOpen, _setIsWarningOpen] = useState(false);

    const {
        cropData,
        selectedAspectRatio,
        executeCrop,
        manualInput,
        trimData = {
            start: video.inputStartTime || 0,
            end: video.inputEndTime || duration || maximumDuration || 0
        }
    } = useComponentStore<VideoCropperState>('VideoCropper', {
        fields: {
            cropData: 'cropData',
            selectedAspectRatio: 'selectedAspectRatio',
            executeCrop: 'executeCrop',
            manualInput: 'manualInput',
            trimData: 'trimData'
        }
    });

    const { videoCropperStateCropData, trimDataStart, trimDataEnd, videoCropperStateManualInput } = useComponentStore<any>('AssetEditor', {
        fields: {
            videoCropperStateCropData: 'videoCropperState.cropData',
            trimDataStart: 'videoCropperState.trimData.start',
            trimDataEnd: 'videoCropperState.trimData.end',
            videoCropperStateManualInput: 'videoCropperState.manualInput'
        }
    });

    const croppingStatusRef = useRef({ executeCrop, isProcessing });
    const [isLoading, setIsLoading] = useState(true);
    const [originalVideoResolution, setOriginalVideoResolution] = useState({ width: 0, height: 0 });
    const [aspectRatio, setAspectRatio] = useState(0);

    const getInitCropData = () => {
        if (!videoCropperStateCropData) {
            return undefined;
        }
        return videoCropperStateCropData;
    };

    // Converts seconds to a float number. string ('01:01') => float (61.0)
    const time2sec = (timeInput) => {
        const arr1 = timeInput.split(':'); // [min, 'sec.mill']
        const minutes = parseInt(arr1[0]) * 60;
        const arr2 = arr1[1].split('.'); // [sec, mill]
        const seconds = parseInt(arr2[0]);
        const milli = parseInt(arr2[1]) / 1000;
        return minutes + seconds + milli;
    };

    /**
     * renderVideo
     * Starts trimming the video
     */
    const renderVideo = async (forceRender) => {
        if (!cropData) {
            cancelCropping();
            return;
        }

        // Warn user if size of crop is smaller than outputSize
        if (forceRender !== true && (cropData.w < outputWidth || cropData.h < outputHeight)) {
            return _setIsWarningOpen(true);
        } else {
            _setIsWarningOpen(false);
        }

        handleProcessing(['']);

        // prepare data for api call
        const duration = trimData.end - trimData.start,
            width = cropData.w,
            height = cropData.h,
            x = cropData.x,
            y = cropData.y;

        const effects: any = [
            {
                type: 'crop',
                width: width,
                height: height,
                x: x,
                y: y
            },
            {
                type: 'trim',
                startTime: AECropperHelper.sec2Time(trimData.start, true, true),
                duration: duration
            }
        ];
        if (outputWidth && outputWidth !== width) {
            effects.push({
                type: 'scale',
                width: outputWidth
            });
        } else if (outputHeight && outputHeight !== height) {
            effects.push({
                type: 'scale',
                height: outputHeight
            });
        }

        const outputFormat = video.extension ? video.extension : (video.originalVideo || video.url).split('.').at(-1) || undefined;

        const response = await VideoModifierService.runVideoEffects(video.originalVideo || video.url, effects, outputFormat);

        if (!response) {
            cancelCropping();
            return;
        }

        const { executeCrop, isProcessing } = croppingStatusRef.current;

        if (!executeCrop || !isProcessing) {
            return;
        }

        setTrimmedVideo({
            response,
            inputStartTime: trimData.start,
            inputEndTime: trimData.end,
            originalVideo: video.originalVideo || video.url,
            cropData: cropData
        });
    };

    useMemo(() => {
        croppingStatusRef.current.isProcessing = isProcessing;
    }, [isProcessing]);

    useMemo(() => {
        croppingStatusRef.current.executeCrop = executeCrop;
        if (executeCrop) {
            renderVideo(false);
        }
    }, [executeCrop]);

    /**
     * Sets the trimmed video data and updates the AssetEditor state.
     * @param {Object} data - The data object containing the trimmed video information.
     * @param {number} data.inputStartTime - The start time of the trimmed video.
     * @param {number} data.inputEndTime - The end time of the trimmed video.
     * @param {string} data.fileType - The type of the file (in this case, 'video').
     * @param {string} data.originalVideo - The original video file.
     * @param {string} data.response.data.url - The URL of the trimmed video.
     * @param {boolean} data.isCropped - Whether or not the video has been cropped.
     * @param {Object} data.cropData - The crop data object containing the width, height, x, and y values.
     * @param {string} data.response.data.url - The URL of the cropped video.
     */
    const setTrimmedVideo = (data) => {
        const onMutationObject = {
            inputStartTime: data.inputStartTime,
            inputEndTime: data.inputEndTime,
            fileType: 'video',
            originalVideo: data.originalVideo,
            url: data.response.data.url,
            isCropped: true,
            cropData: data.cropData
        };

        // Set executeCrop state to false.
        ComponentStoreHelpers.setModel('VideoCropper', 'executeCrop', false);
        ComponentStoreHelpers.setMultiModels('AssetEditor', [
            ['componentKey', 'previewAsset'],
            ['videoCropperState.cropData', data.cropData ? data.cropData : null],
            ['videoCropperState.croppedModifiedAssetSrc', data.response.data.url],
            ['videoCropperState.selectedAspectRatio', selectedAspectRatio],
            ['videoCropperState.trimData', trimData],
            ['videoCropperState.mutationData', onMutationObject]
        ]);
        handleProcessing(false);
    };

    // get ratio value from string
    const getRatio = (str) => {
        const arr = str.split(':');
        return parseInt(arr[0]) / parseInt(arr[1]);
    };

    /**
     * Sets the trim data for the video cropper.
     * @param trimData - The trim data to set.
     */
    const setTrimData = (trimData: TrimData) => {
        ComponentStoreHelpers.setModel('VideoCropper', 'trimData', trimData);
    };

    /**
     * Cancels the current video cropping operation.
     */
    const cancelCropping = () => {
        const { executeCrop, isProcessing } = croppingStatusRef.current;

        if (isProcessing) {
            handleProcessing(false);
            croppingStatusRef.current.isProcessing = false;
        }

        if (executeCrop) {
            croppingStatusRef.current.executeCrop = false;
            ComponentStoreHelpers.setModel('VideoCropper', 'executeCrop', false);
        }
    };

    // set current ratio
    useEffect(() => {
        let aspectRatio = '0';
        const assetEditorCropData = videoCropperStateCropData ?? { w: 0, h: 0, x: 0, y: 0 };

        if (cropMode !== 'sizeBased') {
            if (assetEditorCropData?.w && assetEditorCropData?.h) {
                const i = ratios.findIndex((ratio) => {
                    const ratioInDecimals = getRatio(ratio); // Calculate the ratio in decimal form.
                    const currentRatio = Math.round(ratioInDecimals * 100); // Calculate the current ratio in percentage form.
                    const cropDataRatio = Math.round((assetEditorCropData.w / assetEditorCropData.h) * 100); // Calculate crop data ratio in percentage form.

                    return currentRatio === cropDataRatio;
                });
                aspectRatio = i > -1 ? ratios[i] : '0';
            }
        }

        ComponentStoreHelpers.setData('VideoCropper', {
            cropData: { ...assetEditorCropData },
            selectedAspectRatio: aspectRatio,
            manualInput: videoCropperStateManualInput,
            trimData: {
                start: trimDataStart || video.inputStartTime || 0,
                end: trimDataEnd || video.inputEndTime || duration || maximumDuration || 0
            }
        });

        return () => {
            cancelCropping(); // Stop all cropping operations.
        };
    }, []);

    const setCropData = (cropData: VideoCropData | undefined) => {
        ComponentStoreHelpers.setModel('VideoCropper', 'cropData', cropData);
    };

    const setManualInput = (manualInput: ManualResolutionInput) => {
        ComponentStoreHelpers.setModel('VideoCropper', 'manualInput', manualInput);
    };

    /**
     * Handles the aspect ratio of the video being cropped.
     * If the selected aspect ratio is 0 and the crop mode is not 'sizeBased', sets the aspect ratio to the original aspect ratio of the video and sets the crop data to the original video resolution.
     * Otherwise, sets the aspect ratio based on the current selected aspect ratio.
     */
    const handleAspectRatio = () => {
        const { width, height } = originalVideoResolution;

        if (selectedAspectRatio === '0' && cropMode !== 'free') {
            const originalAspectRatio = ImageFileService.calculateImageRatioByResolution(width, height);
            setAspectRatio(originalAspectRatio); // Set the ratio to the original aspect ratio, this will set the crop selection to the entire video.
            setCropData({ x: 0, y: 0, w: width, h: height }); // Set the crop data to the original video resolution.
        } else {
            const aspectRatioInDecimals = ImageFileService.calculateImageRatio(selectedAspectRatio); // Calculate the select aspect ratio in decimal form.
            setAspectRatio(aspectRatioInDecimals); // Set the ratio in decimals to the current aspect ratio.
        }
    };

    /**
     * Sets the video duration to the asset editor store.
     * @param duration - The duration of the video.
     */
    const setVideoDuration = (duration?: number) => {
        const videoDuration = duration ?? 0;

        ComponentStoreHelpers.setModel('AssetEditor', 'videoCropperState.duration', videoDuration); // Set video duration to the store.
    };

    /**
     * Callback function that is triggered when video is loaded.
     * Gets the original video resolution and sets the isLoading state to false.
     */
    const onVideoLoad = (_, videoRef: React.RefObject<HTMLVideoElement>) => {
        const videoElement = videoRef?.current;

        setIsLoading(false);
        setVideoDuration(videoElement?.duration); // Set video duration to the store.

        if (videoElement) {
            setOriginalVideoResolution({ width: videoElement.videoWidth, height: videoElement.videoHeight });
        }
    };

    useMemo(() => {
        if (cropData) {
            setManualInput({ w: cropData.w, h: cropData.h }); // Set manual input data.
        }
    }, [cropData?.w, cropData?.h]);

    useEffect(() => {
        const assetEditorCropData = getInitCropData();

        setCropData(assetEditorCropData); // Set int crop data.
    }, [videoCropperStateCropData]);

    useEffect(() => {
        handleAspectRatio();
    }, [selectedAspectRatio, originalVideoResolution]);

    return (
        <OverlayLoadingIndicatorWrapper
            text={
                executeCrop
                    ? Translation.get('assetGalleryCropper.assetVideoCropper.croppingVideo', 'content-space')
                    : Translation.get('assetGalleryCropper.assetVideoCropper.loadingVideo', 'content-space')
            }
            isLoading={executeCrop || isLoading}>
            <div className="asset-cropper-view">
                <div className="asset-cropper-view__content">
                    <div className="asset-cropper-view__children">
                        <VideoCropper
                            video={video}
                            minimumDuration={minimumDuration}
                            maximumDuration={maximumDuration}
                            duration={duration}
                            ratio={aspectRatio}
                            canCrop={useCropper}
                            outputWidth={outputWidth}
                            outputHeight={outputHeight}
                            isProcessing={!!isProcessing}
                            changeCropData={setCropData}
                            cropData={cropData}
                            changeTrimData={setTrimData}
                            trimData={trimData}
                            time2sec={time2sec}
                            manualInput={manualInput}
                            cropMode={cropMode}
                            showEditControls={showEditControls}
                            onVideoLoad={(event, videoRef) => {
                                onVideoLoad(event, videoRef);
                            }}
                        />
                    </div>
                </div>

                {cropData && (
                    <Dialog open={isWarningOpen} onClose={() => _setIsWarningOpen(false)}>
                        <DialogTitle>
                            {' '}
                            {Translation.get('assetGalleryDialog.assetVideoCropper.croppedVideoSmallerThanTheOutputSize', 'content-space')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {cropData.w < outputWidth && `Cropped video width: ${cropData.w}px, Output width: ${outputWidth}px`}
                                {cropData.w < outputWidth && cropData.h < outputHeight && <br />}
                                {cropData.h < outputHeight && `Cropped video height: ${cropData.h}px, Output height: ${outputHeight}px`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => _setIsWarningOpen(false)}>{Translation.get('actions.cancel', 'common')}</Button>
                            <Button onClick={() => renderVideo(true)}>{Translation.get('actions.confirm', 'common')}</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        </OverlayLoadingIndicatorWrapper>
    );
};

export default AssetVideoCropperView;
