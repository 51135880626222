import React, { useEffect } from 'react';
import Button from 'components/ui-components-v2/Button';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { VideoCropData, VideoCropperState } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';
import ComponentStore from 'components/data/ComponentStore';
import AssetEditorActionRow from 'components/assets/AssetEditor/components/AssetEditorActionsRow/asset-editor-actions';
import Translation from 'components/data/Translation';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import AssetEditorCropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import CropInputRow from '../../crop-input-row';
import CropperControllerItemList from '../../cropper-controller-item-list';
import CropControllerItem from '../../cropper-controller-item-list/interfaces/cropper-controller-item';
import cropperControllerItems from '../../cropper-controller-item-list/data/controller-items';
import AccordionRow from './accordion-row';
import AssetVideoTrimDetails from './asset-video-trim-details';

import '../styles/video-cropper-controller.scss';

interface VideoCropperControllerState {
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    useCropper: AssetGalleryDialogState['conditionProps']['useCropper'];
    ratios: AssetGalleryDialogState['config']['cropper']['ratios'];
    cropMode: AssetGalleryDialogState['config']['cropper']['cropMode'];
}

interface VideoCropperController {
    selectedAspectRatio: VideoCropperState['selectedAspectRatio'];
    cropData: VideoCropperState['cropData'];
    manualInput: VideoCropperState['manualInput'];
    trimDataStart: number;
    trimDataEnd: number;
    executeCrop: VideoCropperState['executeCrop'];
}
/**
 * This file contains the VideoCropperController component, which is responsible for rendering the video cropper/trimmer UI and handling user interactions.
 * The component uses the useComponentStore hook to manage its state and retrieve data from the AssetGallery and AssetEditor components.
 * It also contains helper functions for calculating aspect ratios and converting time values.
 * The component renders several child components, including CropInputRow, CropperControllerItemList, and AccordionRow.
 */
const VideoCropperController: React.FC = () => {
    const {
        selectedAspectRatio,
        cropData,
        manualInput = { w: 0, h: 0 },
        executeCrop
    } = useComponentStore<VideoCropperController>('VideoCropper', {
        fields: {
            selectedAspectRatio: 'selectedAspectRatio',
            cropData: 'cropData',
            manualInput: 'manualInput',
            executeCrop: 'executeCrop'
        }
    });

    const { outputWidth, outputHeight, useCropper, ratios, cropMode } = useComponentStore<VideoCropperControllerState>('AssetGalleryDialog', {
        fields: {
            outputWidth: 'data.assetData.outputWidth',
            outputHeight: 'data.assetData.outputHeight',
            useCropper: 'conditionProps.useCropper',
            ratios: 'config.cropper.ratios',
            cropMode: 'config.cropper.cropMode'
        }
    });

    /**
     * Updates the manual input for the specified dimension type (width or height) with the provided value.
     * @param type - The type of dimension to update (width or height).
     * @param value - The new value for the specified dimension.
     */
    const handleManualInput = (type: 'width' | 'height', value: string) => {
        const newManualInput = AECropperHelper.getManualInput(type, value, manualInput, selectedAspectRatio);

        ComponentStore.setModel('VideoCropper', 'manualInput', newManualInput);
    };

    const onCropperItemClick = (cropperItem: CropControllerItem) => {
        ComponentStore.setModel('VideoCropper', 'selectedAspectRatio', cropperItem.aspectRatio);
    };

    /**
     * Returns an array of CropControllerItem objects that have been filtered based on the current crop mode.
     * If the crop mode is 'sizeBased', only items with an aspect ratio of '0' will be included.
     * @returns {CropControllerItem[]} An array of filtered CropControllerItem objects.
     */
    const getFilteredCropperItems = () => {
        const filteredItems: CropControllerItem[] = [];

        ratios.forEach((ratio) => {
            const foundItem = cropperControllerItems.find((item) => item.aspectRatio === ratio);

            if (!foundItem) {
                // Default item if not found.
                filteredItems.push({
                    aspectRatio: ratio,
                    icon: 'crop_landscape'
                });

                return;
            }

            const foundItemCopy = { ...foundItem };

            if (cropMode === 'free') {
                if (foundItemCopy.aspectRatio === '0') {
                    // Change title to freeform if the aspect ratio is 0.
                    foundItemCopy.title = Translation.get('assetGalleryCropper.freeForm', 'content-space');
                }
            }

            filteredItems.push(foundItemCopy);
        });

        return filteredItems;
    };

    const onApplyCropClick = () => {
        if (!cropData) {
            return;
        }

        const crop: VideoCropData = { ...cropData };
        const videoCropper: VideoCropperState = ComponentStore.get('VideoCropper');

        ComponentStore.setMultiModels('AssetEditor', [
            ['videoCropperState.cropData', crop],
            ['videoCropperState.trimData.start', videoCropper.trimData?.start],
            ['videoCropperState.trimData.end', videoCropper.trimData?.end]
        ]);
        ComponentStore.setMultiModels('VideoCropper', [['executeCrop', true]]);
    };

    const resetVideoCropperState = () => {
        ComponentStore.setData('VideoCropper', {
            selectedAspectRatio: '0',
            cropData: null,
            manualInput: null,
            executeCrop: false
        });
    };

    useEffect(() => {
        return () => {
            resetVideoCropperState();
        };
    }, []);

    return (
        <div className={'video-cropper-controller'}>
            {!executeCrop ? (
                <>
                    <AccordionRow title="Crop" defaultExpanded={true}>
                        <div className={'video-cropper-controller__crop'}>
                            <CropperControllerItemList
                                items={getFilteredCropperItems()}
                                selectedAspectRatio={selectedAspectRatio}
                                onCropperItemClick={onCropperItemClick}
                            />
                            {!outputWidth && !outputHeight && (
                                <React.Fragment>
                                    <CropInputRow
                                        width={manualInput?.w}
                                        height={manualInput?.h}
                                        isDisabled={!useCropper}
                                        onWidthChange={(e) => handleManualInput('width', e.target.value)}
                                        onHeightChange={(e) => handleManualInput('height', e.target.value)}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </AccordionRow>
                    <AccordionRow title="Trim" defaultExpanded={true}>
                        <AssetVideoTrimDetails />
                    </AccordionRow>
                    <AssetEditorActionRow className="video-cropper-controller__action-row">
                        <Button
                            disabled={AssetEditorCropperHelper.isApplyButtonDisabled(cropData)}
                            onClick={() => onApplyCropClick()}
                            variant="contained"
                            color="primary">
                            {Translation.get('actions.apply', 'common')}
                        </Button>
                    </AssetEditorActionRow>
                </>
            ) : (
                <div className="video-cropper-controller__loading">
                    {Translation.get('assetGalleryCropper.assetVideoCropper.croppingVideo', 'content-space')}
                </div>
            )}
        </div>
    );
};

export default VideoCropperController;
