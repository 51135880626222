import React, { useEffect } from 'react';
import ImageFileService from 'services/image-file/image.service';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import { AssetEditorComponentMapKeys } from 'components/assets/AssetEditor/interfaces/AssetEditorComponentMap';
import { OutpaintData, OutpaintRequestData, OutpaintCanvasOutput } from 'components/assets/AssetOutpaintEditor/interfaces/outpaint';
import AssetImageCropperView from './components/image-cropper-view';
import AssetEditorHelper from '../../../AssetEditor/helpers/asset-editor-helper';
import { ImageCropData, ImageCropperState, PreviewCropData } from '../../interfaces/asset-cropper-state';
import AssetGalleryData from '../../../AssetGalleryDialog/interfaces/AssetGalleryData';
import cropperControllerItems from '../cropper-controller-item-list/data/controller-items';
import AssetEditorCropperHelper from '../../helpers/asset-editor-cropper-helper';

const DEFAULT_CROP_DATA: ImageCropData = {
    aspect: 0,
    selectedRatio: '0',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    unit: '%'
};

interface ImageCropperWrapperState {
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    maxOutputWidth: AssetGalleryDialogState['data']['assetData']['maxOutputWidth'];
    maxOutputHeight: AssetGalleryDialogState['data']['assetData']['maxOutputHeight'];
    outpaintMode: AssetGalleryDialogState['data']['assetData']['outpaintMode'];
    imageFormat: AssetGalleryDialogState['data']['assetData']['imageFormat'];
    imageQuality: AssetGalleryDialogState['data']['assetData']['imageQuality'];
    cropMode: AssetGalleryDialogState['config']['cropper']['cropMode'];
    value: AssetGalleryDialogState['value'];
    config: AssetGalleryDialogState['config'];
    isMainCrop: AssetGalleryDialogState['config']['cropper']['isMainCrop'];
}

interface AssetEditorInternalState {
    originalAssetSrc: string;
    modifiedAssetSrc: string;
    imageCropperState: ImageCropperState;
    originalWidth: number;
    originalHeight: number;
    cropData?: ImageCropData;
    outpaintData?: OutpaintData;
    componentKey: AssetEditorComponentMapKeys;
}
interface Props {
    locked?: boolean;
    imageBoxStyle?: React.CSSProperties;
    reactCropClassName?: string;
    isMainCrop?: boolean; // Determines if asset gallery dialog was open via the main crop button.
    outpaintEditor?: boolean; // Determines if asset gallery dialog was open via the outpaint editor.
}

/**
 * A component that wraps the `AssetImageCropperView` component and provides functionality for cropping images.
 * @param locked - A boolean indicating whether the crop selection is locked or not.
 * @param imageBoxStyle - A CSSProperties object representing the style of the image box.
 * @param reactCropClassName - A string representing the class name of the react crop component.
 */
const ImageCropperWrapper: React.FC<Props> = ({ locked, imageBoxStyle, reactCropClassName }) => {
    const {
        value: image,
        config,
        cropMode,
        outputWidth,
        outputHeight,
        maxOutputWidth,
        maxOutputHeight,
        imageFormat,
        imageQuality,
        isMainCrop,
        outpaintMode
    } = useComponentStore<ImageCropperWrapperState>('AssetGalleryDialog', {
        fields: {
            value: 'value',
            config: 'config',
            cropMode: 'config.cropper.cropMode',
            outputWidth: 'data.assetData.outputWidth',
            outputHeight: 'data.assetData.outputHeight',
            maxOutputWidth: 'data.assetData.maxOutputWidth',
            maxOutputHeight: 'data.assetData.maxOutputHeight',
            imageFormat: 'data.assetData.imageFormat',
            imageQuality: 'data.assetData.imageQuality',
            isMainCrop: 'config.cropper.isMainCrop',
            outpaintMode: 'data.assetData.outpaintMode'
        }
    });

    const {
        originalAssetSrc,
        modifiedAssetSrc,
        originalWidth,
        originalHeight,
        cropData = DEFAULT_CROP_DATA,
        componentKey
    } = useComponentStore<AssetEditorInternalState>('AssetEditor', {
        fields: {
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc',
            originalWidth: 'assetData.width',
            originalHeight: 'assetData.height',
            cropData: 'imageCropperState.cropData',
            componentKey: 'componentKey'
        }
    });

    const { selectedAspectRatio } = useComponentStore<ImageCropperState>('ImageCropper', {
        fields: { selectedAspectRatio: 'selectedAspectRatio' }
    });

    /**
     * Updates value object with the current component store data.
     */
    const getUpdatedValue = (): AssetGalleryData => {
        const url = AssetEditorHelper.getAssetUrl(originalAssetSrc, modifiedAssetSrc);
        return { ...image, crop: cropData, url, editedOriginalImage: url };
    };

    /**
     * Gets the aspect ratio of an image based on the given width and height.
     * @param width - The width of the image.
     * @param height - The height of the image.
     * @returns A string representing the aspect ratio of the image in the format "width:height".
     */
    const getAspectRatio = (width: number, height: number) => {
        if (selectedAspectRatio === '0') {
            return ImageFileService.getImageRatio(width, height);
        }

        return selectedAspectRatio;
    };

    // Todo check width and height values.
    const handleCroppedAsset = (previewCropData: PreviewCropData) => {
        const { crop } = { ...previewCropData };

        const { width, height } = AssetEditorCropperHelper.convertPercentageToPixels(crop?.width ?? 0, crop?.height ?? 0, originalWidth, originalHeight);
        const selectedRatio = getAspectRatio(width, height);

        const cropData: ImageCropData | undefined = previewCropData.crop ? { ...crop, selectedRatio } : undefined;

        const imageCropperState: ImageCropperState | undefined = ComponentStoreHelpers.get('ImageCropper');

        if (imageCropperState) {
            ComponentStoreHelpers.setModel<ImageCropperState, 'cropData'>('ImageCropper', 'cropData', cropData);
        }
    };

    const handleUpdateOutpaintData = (outpaintData: OutpaintCanvasOutput) => {
        const { selectedArea, imagePosition } = outpaintData;

        // Calculate the required extensions
        if (selectedArea && imagePosition) {
            // get the current aspect ratio
            const selectedRatio = getAspectRatio(selectedArea.width, selectedArea.height);

            // If the image need to be cropped (negative extend value), calc crop values
            const cropData = {
                // Set negative extend values as positive x and y (crop selection coords)
                y: Math.max(-imagePosition.extendUp, 0),
                x: Math.max(-imagePosition.extendLeft, 0),
                // Substract negative value from width and height
                w: Math.round(
                    Math.min(imagePosition.width, imagePosition.width + Math.min(imagePosition.extendRight, 0) + Math.min(imagePosition.extendLeft, 0))
                ),
                h: Math.round(
                    Math.min(imagePosition.height, imagePosition.height + Math.min(imagePosition.extendDown, 0) + Math.min(imagePosition.extendUp, 0))
                )
            };

            const outpaintRequestData: OutpaintRequestData = {
                ...imagePosition,
                extendLeft: Math.max(imagePosition.extendLeft, 0),
                extendUp: Math.max(imagePosition.extendUp, 0),
                extendRight: Math.max(imagePosition.extendRight, 0),
                extendDown: Math.max(imagePosition.extendDown, 0)
            };

            const formattedOutPaintData: OutpaintData = { selectedRatio, imagePosition, selectedArea, cropData, outpaintRequestData };

            ComponentStoreHelpers.setData('Outpaint', { outpaintData: formattedOutPaintData });
        }
    };

    /**
     * Sets the selected aspect ratio based on the current mode and output dimensions.
     * If the mode is 'fixed', it calculates the custom aspect ratio and sets it as the selected aspect ratio.
     * If the mode is not 'fixed', it sets the selected aspect ratio to '0'.
     */
    useEffect(() => {
        if ((cropMode === 'fixed' || cropMode === 'ratioBased') && outputWidth > 0 && outputHeight > 0) {
            const customRatio = ImageFileService.getImageRatio(outputWidth, outputHeight);
            ComponentStoreHelpers.setData('ImageCropper', { cropData: cropData, selectedAspectRatio: customRatio });
        } else if (!selectedAspectRatio) {
            const item = cropperControllerItems.find((item) => {
                const itemAspectRatio = ImageFileService.calculateImageRatio(item.aspectRatio).toFixed(2);
                const initialAspectRatio = ImageFileService.calculateImageRatio(cropData?.selectedRatio ?? '0').toFixed(2);

                return itemAspectRatio === initialAspectRatio;
            }); // Check if aspect ratio exists the items list.

            // Only calculate the aspect ratio if the isMainCrop is true and the max output width and height are greater than 0.
            if (isMainCrop && maxOutputHeight > 0 && maxOutputWidth > 0) {
                const ratio = ImageFileService.getImageRatio(maxOutputWidth, maxOutputHeight); // Set current aspect ratio to the max output width and height.
                ComponentStoreHelpers.setData('ImageCropper', { cropData: cropData, selectedAspectRatio: ratio });
            } else {
                // Set original aspect ratio
                if ((cropMode === 'fixed' || cropMode === 'ratioBased') && item?.aspectRatio === '0') {
                    // Calculate the original aspect ratio.
                    const ratio = ImageFileService.getImageRatio(originalWidth, originalHeight);

                    ComponentStoreHelpers.setData('ImageCropper', { cropData: cropData, selectedAspectRatio: ratio });
                } else {
                    let ratio = item?.aspectRatio ?? '0';

                    // If the aspect ratio is not found in the items list, calculate the aspect ratio based on the image dimensions.
                    if ((cropMode === 'fixed' || cropMode === 'ratioBased') && !item?.aspectRatio) {
                        ratio = ImageFileService.getImageRatio(originalWidth, originalHeight);
                    }

                    // Set current aspect ratio.
                    ComponentStoreHelpers.setData('ImageCropper', { cropData: cropData, selectedAspectRatio: ratio });
                }
            }
        }
    }, [originalHeight, originalWidth]);

    useEffect(() => {
        return () => {
            ComponentStoreHelpers.remove('ImageCropper');
        };
    }, []);

    return (
        <AssetImageCropperView
            componentKey={componentKey}
            value={getUpdatedValue()}
            outputHeight={outputHeight}
            outputWidth={outputWidth}
            maxOutputHeight={maxOutputHeight}
            maxOutputWidth={maxOutputWidth}
            imageFormat={imageFormat}
            imageQuality={imageQuality}
            config={config.cropper}
            cropMode={cropMode}
            ratio={ImageFileService.calculateImageRatio(selectedAspectRatio)}
            onChange={(asset) => handleCroppedAsset(asset)}
            onOutpaintChange={(asset) => handleUpdateOutpaintData(asset)}
            locked={locked}
            imageBoxStyle={imageBoxStyle}
            reactCropClassName={reactCropClassName}
            outpaintMode={outpaintMode}
        />
    );
};

export default ImageCropperWrapper;
