import React, { useEffect, useState } from 'react';
import ImageFileService from 'services/image-file/image.service';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { ImageCropData, ImageCropperState } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import CropperControllerItemList from '../../cropper-controller-item-list';
import CropControllerItem from '../../cropper-controller-item-list/interfaces/cropper-controller-item';
import cropperControllerItems from '../../cropper-controller-item-list/data/controller-items';
import CropInputRow from '../../crop-input-row';

import '../styles/image-cropper-controller.scss';

interface ImageCropperControllerState {
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    cropMode: AssetGalleryDialogState['config']['cropper']['cropMode'];
    ratios: AssetGalleryDialogState['config']['cropper']['ratios'];
    useImageSize: AssetGalleryDialogState['config']['cropper']['useImageSize'];
}
interface AssetEditorInternalState {
    originalHeight: number;
    originalWidth: number;
}

/**
 * Renders the controller for the image cropper, which allows the user to select an aspect ratio and apply the crop to the image.
 */
const ImageCropperController: React.FC = () => {
    const {
        selectedAspectRatio,
        cropData,
        manualInput = { w: 0, h: 0 }
    } = useComponentStore<ImageCropperState>('ImageCropper', {
        fields: {
            selectedAspectRatio: 'selectedAspectRatio',
            cropData: 'cropData',
            manualInput: 'manualInput'
        }
    });

    const { originalHeight, originalWidth } = useComponentStore<AssetEditorInternalState>('AssetEditor', {
        fields: { originalHeight: 'assetData.height', originalWidth: 'assetData.width' }
    });

    const { outputWidth, outputHeight, cropMode, ratios, useImageSize } = useComponentStore<ImageCropperControllerState>('AssetGalleryDialog', {
        fields: {
            outputWidth: 'data.assetData.outputWidth',
            outputHeight: 'data.assetData.outputHeight',
            cropMode: 'config.cropper.cropMode',
            ratios: 'config.cropper.ratios',
            useImageSize: 'config.cropper.useImageSize'
        }
    });

    const [cropDataInPixels, setCropDataInPixels] = useState<ImageCropData>();

    const onCropperItemClick = (cropperItem: CropControllerItem) => {
        ComponentStoreHelpers.setModel('ImageCropper', 'selectedAspectRatio', cropperItem.aspectRatio);
    };

    const onApplyCropClick = () => {
        if (!cropData) {
            return;
        }

        const crop: ImageCropData = { ...cropData, selectedRatio: selectedAspectRatio };

        // Go back to preview content and set current crop.
        ComponentStoreHelpers.setMultiModels('AssetEditor', [
            ['imageCropperState.cropData', crop],
            ['componentKey', 'previewAsset']
        ]);
    };

    /**
     * Filters the cropper items based on the current mode and output dimensions.
     * If width and height are provided in either 'free' or 'fixed' mode then return only freeform or the fixed item, else return everything.
     * If the mode is 'fixed', it calculates the custom aspect ratio and updates the item's title and aspect ratio accordingly.
     * @returns {CropControllerItem[]} The filtered cropper items.
     */
    const getFilteredCropperItems = () => {
        const filteredItems: CropControllerItem[] = [];

        if (useImageSize) {
            filteredItems.push({
                aspectRatio: `${outputWidth}:${outputHeight}`,
                title: 'Image size',
                icon: 'image'
            });
        }

        ratios.forEach((ratio) => {
            const foundItem = cropperControllerItems.find((item) => item.aspectRatio === ratio);

            if (!foundItem) {
                filteredItems.push({
                    aspectRatio: ratio,
                    icon: 'crop_landscape'
                });

                return;
            }

            const foundItemCopy = { ...foundItem }; // Create a copy of the item to modify

            if (cropMode === 'free') {
                if (foundItemCopy.aspectRatio === '0') {
                    // Change title to freeform if the aspect ratio is 0.
                    foundItemCopy.title = Translation.get('assetGalleryCropper.freeForm', 'content-space');
                }
            } else {
                if (outputWidth > 0 && outputHeight > 0) {
                    if (foundItemCopy.aspectRatio !== '0') {
                        return;
                    }

                    // Fixed aspect ratio.
                    const ratio = ImageFileService.getImageRatio(outputWidth, outputHeight);
                    foundItemCopy.title = ratio;
                    foundItemCopy.aspectRatio = ratio;
                } else if (foundItemCopy.aspectRatio === '0') {
                    // Original aspect ratio flow
                    let ratio = '0';

                    if (originalWidth && originalHeight) {
                        ratio = ImageFileService.getImageRatio(originalWidth, originalHeight);
                    }

                    foundItemCopy.aspectRatio = ratio;
                }
            }

            filteredItems.push(foundItemCopy);
        });

        return filteredItems;
    };

    /**
     * Updates the manual input for the specified dimension type (width or height) with the provided value.
     * @param type - The type of dimension to update (width or height).
     * @param value - The new value for the specified dimension.
     */
    const handleManualInput = (type: 'width' | 'height', value: string) => {
        const defaultData = cropDataInPixels ? { w: cropDataInPixels.width, h: cropDataInPixels.height } : manualInput; // Get default from crop data if exists, else from manual input.
        const unValidatedManualInput = AECropperHelper.getManualInput(type, value, defaultData, selectedAspectRatio);
        const { w, h } = AECropperHelper.validateManualInput(unValidatedManualInput.w, unValidatedManualInput.h, originalWidth, originalHeight);

        if (cropData) {
            const isDimensionWithinBounds = AECropperHelper.isDimensionWithinBounds(w, h, originalWidth, originalHeight); // Make sure the new dimensions are not larger than the original image dimensions.

            if (isDimensionWithinBounds) {
                ComponentStoreHelpers.setModel<ImageCropperState, 'manualInput'>('ImageCropper', 'manualInput', { w, h });
            }
        }
    };

    useEffect(() => {
        if (cropData) {
            const { width, height } = AECropperHelper.convertPercentageToPixels(cropData?.width ?? 0, cropData?.height ?? 0, originalWidth, originalHeight);

            setCropDataInPixels({ ...cropData, width, height }); // Set crop data in pixels.
        }
    }, [cropData]);

    return (
        <div className="image-cropper-controller">
            <div className="image-cropper-controller__title">Aspect Ratio</div>
            <CropperControllerItemList items={getFilteredCropperItems()} selectedAspectRatio={selectedAspectRatio} onCropperItemClick={onCropperItemClick} />
            {!outputWidth && !outputHeight && (
                <CropInputRow
                    width={cropDataInPixels?.width ?? 0}
                    height={cropDataInPixels?.height ?? 0}
                    onWidthChange={(e) => handleManualInput('width', e.target.value)}
                    onHeightChange={(e) => handleManualInput('height', e.target.value)}
                />
            )}
            <div className="image-cropper-controller__actions">
                <Button
                    disabled={AECropperHelper.isApplyButtonDisabled(cropData)}
                    className="image-cropper-controller__actions__button"
                    onClick={() => onApplyCropClick()}
                    variant="contained"
                    color="primary">
                    {Translation.get('actions.apply', 'common')}
                </Button>
            </div>
        </div>
    );
};

export default ImageCropperController;
