import Icon from 'components/ui-components-v2/Icon';
import React from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Button from 'components/ui-components-v2/Button';
import AssetFlipper, { AssetFlipperAction } from '../interfaces/asset-flipper-controller-item';

import '../styles/asset-flipper-controller-item.scss';

interface Props extends AssetFlipper {
    onClick: (action: AssetFlipperAction) => void;
}

/**
 * A component that represents a single item in the asset flipper controller.
 * @param onClick - The function to be called when the item is clicked.
 * @param icon - The icon to be displayed in the item.
 * @param action - The action to be performed when the item is clicked.
 * @param tooltip - The tooltip to be displayed when the item is hovered over.
 */
const AssetFlipperControllerItem: React.FC<Props> = ({ onClick, icon, action, tooltip }) => {
    return (
        <div>
            <Tooltip title={tooltip}>
                <Button className={'asset-flipper-controller-item'} variant="outlined" onClick={() => onClick(action)}>
                    <Icon className={`${action === 'flipVertical' && 'asset-flipper-controller-item__icon-flipped'}`}>{icon}</Icon>
                </Button>
            </Tooltip>
        </div>
    );
};

export default AssetFlipperControllerItem;
