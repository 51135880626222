import React from 'react';
import TextField from 'components/ui-components-v2/TextField';
import Translation from 'components/data/Translation';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import VideoCropperController from './video-cropper-controller';

import '../styles/asset-video-trim-details.scss';

/**
 * Responsible for displaying the start time, end time, and total duration of the video trim.
 * It's important to have this in a separate component to avoid re-rendering the entire video cropper component when the trim data changes.
 */
const AssetVideoTrimDetails: React.FC = () => {
    const { trimDataStart = 0, trimDataEnd = 0 } = useComponentStore<VideoCropperController>('VideoCropper', {
        fields: { trimDataStart: 'trimData.start', trimDataEnd: 'trimData.end' }
    });

    return (
        <div className="asset-video-trim-details">
            <div className="asset-video-trim-details__row">
                <div className="asset-video-trim-details__row__text">{Translation.get('assetGalleryCropper.assetVideoCropper.startTime', 'content-space')}</div>
                <TextField
                    className="asset-video-trim-details__row__textField"
                    variant="outlined"
                    value={AECropperHelper.sec2Time(trimDataStart, true, false)}
                    disabled
                />
            </div>
            <div className="asset-video-trim-details__row">
                <div className="asset-video-trim-details__row__text">{Translation.get('assetGalleryCropper.assetVideoCropper.endTime', 'content-space')}</div>
                <TextField
                    className="asset-video-trim-details__row__textField"
                    variant="outlined"
                    value={AECropperHelper.sec2Time(trimDataEnd, true, false)}
                    disabled
                />
            </div>
            <div className="asset-video-trim-details__row">
                <div className="asset-video-trim-details__row__text">
                    {Translation.get('assetGalleryCropper.assetVideoCropper.trimDuration', 'content-space')}
                </div>
                <div className="asset-video-trim-details__row__duration">{AECropperHelper.getTrimDuration(trimDataStart, trimDataEnd)}</div>
            </div>
        </div>
    );
};

export default AssetVideoTrimDetails;
