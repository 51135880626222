import React, { useEffect, useState } from 'react';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { ManualResolutionInput, OutpaintState } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';
import AECropperHelper from 'components/assets/AssetGalleryCropper/helpers/asset-editor-cropper-helper';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import { SelectedArea } from 'components/assets/AssetOutpaintEditor/interfaces/outpaint';
import CropInputRow from '../../crop-input-row';
import '../styles/image-cropper-controller.scss';

interface ImageCropperControllerState {
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    cropMode: AssetGalleryDialogState['config']['cropper']['cropMode'];
    ratios: AssetGalleryDialogState['config']['cropper']['ratios'];
}
interface AssetEditorInternalState {
    originalHeight: number;
    originalWidth: number;
    outpaintState: OutpaintState;
}

/**
 * Renders the controller for the image cropper, which allows the user to select an aspect ratio and apply the crop to the image.
 */
const OutpaintController: React.FC = () => {
    const {
        selectedAspectRatio,
        outpaintData,
        manualInput = { h: 0, w: 0 }
    } = useComponentStore<OutpaintState>('Outpaint', {
        fields: {
            selectedAspectRatio: 'selectedAspectRatio',
            outpaintData: 'outpaintData',
            manualInput: 'manualInput'
        }
    });

    const { outpaintState } = useComponentStore<AssetEditorInternalState>('AssetEditor', {
        fields: { outpaintState: 'outpaintState' }
    });

    const { outputWidth, outputHeight } = useComponentStore<ImageCropperControllerState>('AssetGalleryDialog', {
        fields: {
            outputWidth: 'data.assetData.outputWidth',
            outputHeight: 'data.assetData.outputHeight'
        }
    });

    const [cropDataInPixels, setCropDataInPixels] = useState<SelectedArea>();

    /**
     * Updates the manual input for the specified dimension type (width or height) with the provided value.
     * @param type - The type of dimension to update (width or height).
     * @param value - The new value for the specified dimension.
     */
    const handleManualInput = (type: 'width' | 'height', value: string) => {
        const defaultData = cropDataInPixels ? { w: cropDataInPixels.width, h: cropDataInPixels.height } : manualInput; // Get default from crop data if exists, else from manual input.
        const unValidatedManualInput = AECropperHelper.getManualInput(type, value, defaultData, selectedAspectRatio);

        const newWidthAndHeight: ManualResolutionInput = {
            w: Math.min(unValidatedManualInput.w, outpaintState.processedMaxOutputWidth),
            h: Math.min(unValidatedManualInput.h, outpaintState.processedMaxOutputHeight)
        };

        if (cropDataInPixels) {
            setCropDataInPixels({
                ...cropDataInPixels,
                width: newWidthAndHeight.w,
                height: newWidthAndHeight.h
            });
        }

        ComponentStoreHelpers.setModel<OutpaintState, 'manualInput'>('Outpaint', 'manualInput', newWidthAndHeight);
    };

    useEffect(() => {
        if (outpaintData) {
            setCropDataInPixels(outpaintData.selectedArea); // Set crop data in pixels.
            ComponentStoreHelpers.setModel<OutpaintState, 'manualInput'>('Outpaint', 'manualInput', {
                w: outpaintData.selectedArea.width,
                h: outpaintData.selectedArea.height
            });
        }
    }, [outpaintData]);

    return (
        <div className="image-cropper-controller">
            {!outputWidth && !outputHeight && (
                <CropInputRow
                    width={cropDataInPixels?.width}
                    height={cropDataInPixels?.height}
                    onWidthChange={(e) => handleManualInput('width', e.target.value)}
                    onHeightChange={(e) => handleManualInput('height', e.target.value)}
                />
            )}
        </div>
    );
};

export default OutpaintController;
